import React, { useEffect, useState } from "react";


 const ServiceOverview = (props) => {

return(
<>
<div className="col-xl-8 col-lg-8">
                              <div className="sv-details-wrapper">
                                 <div className="sv-details-thumb mb-45">
                                    <img className="w-100" src={props.service.Service_image} alt="" />
                                 </div>
                                 <div className="sv-details-title-box mb-55">
                                    <h4 className="sv-details-title">{props.service.Service_name} Service Overview</h4>
                                    <p>{props.service.Service_detail}</p>
                                 </div>
                                 <div className="sv-details-text mb-35">
                                    <h4 className="sv-details-text-title pb-10">Going Beyond the Usual</h4>
                                    <ul>
                                       {props.servicePointList.map((sp) => (
                                          <li>
                                             <i className="fal fa-check"></i>
                                             <p>
                                                {sp.Title} <br />
                                                <span>{sp.Description}</span>
                                             </p>
                                          </li>
                                       ))}

                                       {/* <li>
                                 <i className="fal fa-check"></i>
                                 <p>
                                    Product Quality: <br/>
                                    <span>Automatically syncs across all your devices. You can also access....</span>
                                 </p>
                              </li>
                              <li>
                                 <i className="fal fa-check"></i>
                                 <p>
                                    On Demand Design: <br/>
                                    <span>Images, videos, PDFs and audio files are supported.</span>
                                 </p>
                              </li>
                              <li>
                                 <i className="fal fa-check"></i>                                 
                                 <p>
                                    Choice of Service:
                                    <span>Whatever your business needs, you can choose a service.</span>
                                 </p>
                              </li> */}
                                    </ul>
                                 </div>
                                 <div className="sv-details-text-2">
                                    <h4 className="sv-details-text-title">The Challange</h4>
                                    <p>{props.service.Service_challenge}</p>
                                 </div>

                              </div>
                           </div>

</>
);
};
export default  ServiceOverview;