import React, { useState } from "react";
import { useFormik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { LoadingOutlined } from "@ant-design/icons";

import {
   inquiry,
} from "../../Services/GlobalMasters/contactService";
import {
   Button, Modal, ModalFooter,
   ModalHeader, ModalBody
} from "reactstrap"
import ResponseStatus from "../Common/ResponseStatus";

const InquireNow = ({ Project, setModalInquiryNow }) => {

   const [modal, setModal] = React.useState(false);
   const toggle = () => setModal(!modal);
   const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
   const [errorMessage, setErrorMessage] = useState(undefined);
   const [resHeader, setResHeader] = useState();
   const [resMessage, setResMessage] = useState();
   const [isLoading, setIsLoading] = useState(false);
   //   const [open, setOpen] = React.useState(false);
   //   const handleClose = () => {
   //      setOpen(false);
   //  };
   const UserInquiry = async (user) => {
      setIsLoading(true);
      debugger;
      
      //toggle = setModal(!modal);
        
       try {
          debugger;
          const response = await inquiry({
             Contact_name: user.name,
             Email: user.email,
             Phone: user.phone.toString(),
             Project: Project.name.modalData,
             Message: user.message,
          });
          if (response?.status === "SUCCESS") {
             /**This is for save company details */
                 setResHeader("SUCCESS");
       
                setResMessage("Your Inquiry has been sent successfully. Our team will  reachout to you soon. Thank you!");
               setErrorMessage(undefined);
                  toggle();

          } else {
               setErrorMessage(response?.message);
               setResHeader("ERROR");
               setResMessage(response?.message);
               toggle();
          }
       } catch (error) {
           setErrorMessage(error);
            setResHeader("ERROR");
            setResMessage(error);
            toggle();
       } finally {
          setIsLoading(false);
       }
   };

   const formik = useFormik({
      initialValues: {
         name: '',
         email: '',
         phone: '',
         message: '',
      },

      validationSchema: Yup.object({
         name: Yup.string()
            .min(2, 'Must be 2 characters or More')
            .max(25)
            .required('Please Enter Name'),

         email: Yup.string()
            .email('Invalid email address')
            .required('Please Enter Email'),
         phone: Yup.string()
            // .max(20, 'Must be 20 characters')
             .matches(phoneRegExp, "Phone number is not valid")
            .required('Please Enter Phone'),
         message: Yup.string()
            .min(3, 'Must be 3 characters or More')

            .required('Please Enter Message'),
      }),

      onSubmit: values => {
             UserInquiry(values);
           
      },
   });

   const closeBothModals = () => {
      
      //Closer the message Modal
      setModal(false);


      //Close the Inquiry Modal
      setModalInquiryNow(false);
   };

    
   return (

      <>
         <div class="contact-form-area pb-50">
            <div class="container">
               <div class="row gx-0">
                  <div class="col-xl-12">
                     <div class="contact-form-right-warp">
                        <div class="postbox__comment-form">
                           <form
                              action="#"
                              onSubmit={(e) => {
                                 e.preventDefault();
                                 formik.handleSubmit();
                                 return false;
                              }}
                              class="box"
                           >
                              <div class="row gx-20">
                                 <div class="col-12">
                                    <div class="postbox__comment-input mb-20">
                                       <input
                                          type="text"
                                          autoComplete="off"
                                          name="name"
                                          id="name"
                                          placeholder="Name"
                                          value={formik.values.name}
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          class="inputText"
                                       />
                                       {formik.touched.name && formik.errors.name ? (
                                          <div className="inputError pl-20">{formik.errors.name}</div>
                                       ) : null}
                                       <span class="floating-label"></span>
                                    </div>
                                 </div>
                                 <div class="col-12">
                                    <div class="postbox__comment-input mb-20">
                                       <input
                                          type="text"
                                          autoComplete="off"
                                          name="email"
                                          id="email"
                                          placeholder="Your Email"
                                          value={formik.values.email}
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          class="inputText"
                                          //required
                                       />
                                       {formik.touched.email && formik.errors.email ? (
                                          <div className="inputError pl-20">{formik.errors.email}</div>
                                       ) : null}
                                       <span class="floating-label"></span>
                                    </div>
                                 </div>
                                 <div class="col-12">
                                    <div class="postbox__comment-input mb-25">
                                       <input
                                          type="text"
                                          autoComplete="off"
                                          name="phone"
                                          id="pone"
                                          placeholder="Phone Number"
                                          value={formik.values.phone}
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          class="inputText"
                                         // required
                                       />
                                       {formik.touched.phone && formik.errors.phone ? (
                                          <div className="inputError pl-20">{formik.errors.phone}</div>
                                       ) : null}
                                       <span class="floating-label"></span>
                                    </div>
                                 </div>
                                 <div class="col-12">
                                 </div>
                                 <div class="col-xxl-12">
                                    <div class="postbox__comment-input mb-20">
                                       <textarea class="textareaText"
                                          autoComplete="off"
                                          name="message"
                                          id="message"
                                          placeholder="Message..."
                                          value={formik.values.message}
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                         // required
                                       >

                                       </textarea>
                                       {formik.touched.message && formik.errors.message ? (
                                          <div className="inputError pl-20">{formik.errors.message}</div>
                                       ) : null}
                                       <span class="floating-label-2"></span>
                                    </div>
                                 </div>
                                 <div class="col-xxl-12">
                                    <div class="postbox__btn-box">
                                       <button class="submit-btn w-100" type="submit"  >
                                       {isLoading ? (
                                        <LoadingOutlined
                                        style={{
                                          fontSize: 24,
                                          marginRight: "5px",
                                        }}
                                        spin
                                      />
                                    ) : (
                                        <></>
                                    )}                                  

                                          Submit</button>
                                       {/* onClick={handleClose} */}
                                    </div>
                                 </div>
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
        

         <ResponseStatus
         Responsemodal={modal}
         Responsetoggle ={toggle}

        ResponseHeader = {resHeader}
        ResponseMessage = {resMessage}
        closeBothModals = {closeBothModals}
        />

      </>
   );
}

export default InquireNow;
