import React from "react";  
import Header1 from "../../Common/Header1";
import Footer1 from "../../Common/Footer1";
export default function About() {


    return (
      <>
      <Header1/>
        <div id="smooth-wrapper">
        <div id="smooth-content">
  
           <main>
  
           {/* <!-- breadcrumb-area-start --> */}
            <div class="breadcrumb__area breadcrumb-height-2 breadcrumb-overlay p-relative fix"
           >
             {/* data-background="assets/img/project/project-brdcrmb-bg.jpg"> */}
               <div class="breadcrumb__shape-2 z-index-4">
                  <img src="assets/img/breadcrumb/breadcrumb-shape-2-2.png" alt=""/>
               </div>
               <div class="breadcrumb__shape-3 z-index-4">
                  <img src="assets/img/breadcrumb/breadcrumb-sub-2.png" alt=""/>
               </div>
               <div class="breadcrumb__shape-4 z-index-4">
                  <img src="assets/img/breadcrumb/breadcrumb-sub-3.png" alt=""/>
               </div>
               <div class="container">
                  <div class="row justify-content-center">
                     <div class="col-xl-10">
                        <div class="breadcrumb__content z-index-3 text-center">
                           <h3 class="breadcrumb__title tp-char-animation text-black"> About TheDigitalogic </h3>
                           <div class="breadcrumb__text wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".6s">
                              <p>We have an experienced team of production and inspection personnel <br/> 
                              to ensure
                                 quality.</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
  
              {/* <!--ab-brand-area-start --> */}
              <div class="ab-brand-area pt-100">
                 <div class="container">
                    <div class="ab-brand-border-bottom pb-90">
                       <div class="row">
                          <div class="col-12">
                             <div class="ab-brand-section-box text-center mb-50">
                                <h4 class="ab-brand-title">Trusted by Various Business</h4>
                                     <p>TheDigitaLogic is a leading software development company that specializes in
                                          Softwrae Development, Application Development, Mobile App Development
                                        Established in [2021], we have been at the forefront of technological innovation,
                                        delivering cutting-edge solutions to businesses across industries.
                                     </p>
                             </div>
                          </div>
                       </div>
                       <div class="row justify-content-center">
                          <div class="col-xl-10">
                             <div class="row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 justify-content-center">
                                <div class="col wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".2s">
                                   <div class="ab-brand-item mb-25">
                                      <img src="assets/img/brand/brand-inner-1.png" alt=""/>
                                   </div>
                                </div>
                                <div class="col wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".4s">
                                   <div class="ab-brand-item mb-25">
                                      <img src="assets/img/brand/brand-inner-2.png" alt=""/>
                                   </div>
                                </div>
                                <div class="col wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".6s">
                                   <div class="ab-brand-item mb-25">
                                      <img src="assets/img/brand/brand-inner-3.png" alt=""/>
                                   </div>
                                </div>
                                <div class="col wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".8s">
                                   <div class="ab-brand-item mb-25">
                                      <img src="assets/img/brand/brand-inner-4.png" alt=""/>
                                   </div>
                                </div>
                                <div class="col wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".9s">
                                   <div class="ab-brand-item mb-25">
                                      <img src="assets/img/brand/brand-inner-5.png" alt=""/>
                                   </div>
                                </div>
                                <div class="col wow tpfadeUp" data-wow-duration=".9s" data-wow-delay="1s">
                                   <div class="ab-brand-item mb-25">
                                      <img src="assets/img/brand/brand-inner-6.png" alt=""/>
                                   </div>
                                </div>
                                <div class="col wow tpfadeUp" data-wow-duration=".9s" data-wow-delay="1.1s">
                                   <div class="ab-brand-item mb-25">
                                      <img src="assets/img/brand/brand-inner-7.png" alt=""/>
                                   </div>
                                </div>
                                <div class="col wow tpfadeUp" data-wow-duration=".9s" data-wow-delay="1.2s">
                                   <div class="ab-brand-item mb-25">
                                      <img src="assets/img/brand/brand-inner-8.png" alt=""/>
                                   </div>
                                </div>
                                <div class="col wow tpfadeUp" data-wow-duration=".9s" data-wow-delay="1.3s">
                                   <div class="ab-brand-item mb-25">
                                      <img src="assets/img/brand/brand-inner-9.png" alt=""/>
                                   </div>
                                </div>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
              {/* <!--ab-brand-area-end --> */}
  
              {/* <!--ab-company-area-start --> */}
              <div class="ab-company-area pt-105 pb-100">
                 <div class="container">
                    <div class="row ab-company-section-space">
                       <div class="col-xl-6">
                          <div class="ab-company-section-box">
                             <h4 class="inner-section-subtitle">ABOUT THE COMPANY</h4>
                             <h3 class="tp-section-title">TheDigitalogic is Made <br/>
                                For the Technosavvy.</h3>
                          </div>
                       </div>
                       <div class="col-xl-6 wow tpfadeRight" data-wow-duration=".9s" data-wow-delay=".5s"
                         style={{ visibility:'visible', animationDuration:'0.9s',animationDelay:'0.5s',animationName:'tpfadeRight'}}
                       >
                          <div class="ab-company-right">
                             <div class="ab-company-section-text">
                                     <p class="pb-10">Our Approach
                                        TheDigitaLogic uses Agile Methodology
                                        We follow an Agile development methodology,
                                         allowing us to adapt to changing requirements and deliver
                                          high-quality software in iterative cycles.
                                           This ensures transparency, flexibility, and 
                                           client satisfaction throughout the development process.

                                     </p>
                                     <p class="pb-10">Website Design & Development
                                        Your website is the most important component of your online brand and marketing
                                        strategy. Let our skilled Long Island website designers work with you to create
                                        a user-friendly, optimized, responsive and mobile-friendly website.
                                     </p>
                                <p><span>Our clean and simple APIs and transparent SaaS 
                                 model will give you complete peace of mind.</span></p>
                             </div>
                          </div>
                       </div>
                    </div>
                    <div class="row align-items-center">
                       {/* <div class="col-xl-4">
                          <div class="ab-company-video">
                             <a class="popup-video" href="/#"><i class="fas fa-play"></i></a>
                             <span>Watch Demo</span>
                          </div>
                       </div>*/}
                       <div class="col-xl-8"> 
                          <div class="row">
                             <div class="col-md-4 col-sm-4 mb-40">
                                <div class="ab-company-fun-fact-wrap d-flex justify-content-start">
                                   <div class="ab-company-fun-fact">
                                      <span>USE BY</span>
                                      <h4>50<em>+</em></h4>
                                      <p>Customers</p>
                                   </div>
                                </div>
                             </div>
                             <div class="col-md-4 col-sm-4 mb-40">
                                <div class="ab-company-fun-fact-wrap d-flex justify-content-md-center justify-content-left">
                                   <div class="ab-company-fun-fact">
                                      <span>Developed</span>
                                      <h4>15<em>+</em></h4>
                                      <p>Projects</p>
                                   </div>
                                </div>
                             </div>
                             <div class="col-md-4 col-sm-4 mb-40">
                                <div class="ab-company-fun-fact-wrap ab-company-border-none d-flex justify-content-md-center justify-content-left">
                                   <div class="ab-company-fun-fact">
                                      <span>IN</span>
                                      <h4>5<em>+</em></h4>
                                      <p>Countries using</p>
                                   </div>
                                </div>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
              {/* <!--ab-company-area-end --> */}
  
              {/* <!-- tp-about-area-start --> */}
              <div class="tp-about__area tp-about__pt-pb pb-160">
                 <div class="container">
                    <div class="row align-items-center">
                       <div className="col-xl-6 col-lg-6 wow tpfadeLeft" data-wow-duration=".9s" data-wow-delay=".2s"
                       style={{ visibility:'visible', animationDuration:'0.9s',animationDelay:'0.2s',animationName:'tpfadeLeft'}}
                       >
                          <div class="tp-about__img-wrapper text-center text-lg-end p-relative">
                             <div class="tp-about__bg-shape">
                                <img src="assets/img/about/about-bg-shape.png" alt=""/>
                             </div>
                             <div class="tp-about__main-img z-index">
                                <img src="assets/img/about/about-2.jpg" alt=""/>
                             </div>
                             <div class="tp-about__sub-img-1 d-none d-sm-block z-index-3">
                                <img src="assets/img/about/about-1.jpg" alt=""/>
                             </div>
                             <div class="tp-about__sub-img-2 d-none d-sm-block">
                                <img src="assets/img/about/about-3.jpg" alt=""/>
                             </div>
                             <div class="tp-about__sub-img-3 d-none d-sm-block z-index-3">
                                <img src="assets/img/about/about-5.png" alt=""/>
                             </div>
                          </div>
                       </div>
                       <div class="col-xl-6 col-lg-6 wow tpfadeRight" data-wow-duration=".9s" data-wow-delay=".6s"
                       style={{ visibility:'visible', animationDuration:'0.9s',animationDelay:'0.6s',animationName:'tpfadeRight'}}>
                                             <div class="tp-about__right">
                             <div class="tp-about__section-box">
                                <h4 class="tp-section-subtitle">OVER 50+ CLIENT</h4>
                                <h3 class="tp-section-title mb-15">Why Choose TheDigitaLogic
                                </h3>
                                     <p>
                                        With a successful track record of delivering projects
                                         on time and within budget, we have earned the trust of our clients.
                                     </p>
                             </div>
                             <div class="tp-about__list">
                                <ul>
                                   <li><i class="fal fa-check"></i>Dedicated Team</li>
                                   <li><i class="fal fa-check"></i>Client-Centric Approach</li>
                                   <li><i class="fal fa-check"></i>Collaboration and Communication</li>
                                </ul>
                             </div>
                             {/* <div class="tp-about__btn">
                                <a class="tp-btn tp-btn-inner tp-btn-hover alt-color-black" href="/#">
                                   <span>About Us</span>
                                   <b></b>
                                </a>
                             </div> */}
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
              {/* <!-- tp-about-area-end --> */}
  
              {/* <!-- tp-team-area-start --> */}
              <div class="tp-team-area grey-bg pt-120 pb-95">
                 <div class="container">
                    <div class="tp-team-section-wrapper tp-team-inner-section mb-70">
                       <div class="row align-items-end">
                          <div class="col-xl-6 col-lg-6 col-md-6">
                             <div class="tp-team-section-box text-center text-sm-start">
                                <h5 class="inner-section-subtitle pb-10">THE TEAM</h5>
                                <h3 class="tp-section-title mb-0 text-black">Our Leaders</h3>
                             </div>
                          </div>
                          <div class="col-xl-6 col-lg-6 col-md-6">
                             <div class="tp-team-top-content text-center text-md-end">
                                {/* <a class="tp-btn-inner tp-btn-hover alt-color-orange" href="/#">
                                   <span>View All</span>
                                   <b></b>
                                </a> */}
                             </div>
                          </div>
                       </div>
                    </div>
                    <div class="row">
                       <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-50 tp-team-border-right tp-border-after-1 team-inner-border-right">
                          <div class="tp-team-item text-center tp-team-inner-title-color z-index">
                             <div class="tp-team-img">
                                <img src="assets/img/team/team-4-1.png" alt=""/>
                             </div>
                             <div class="tp-team-content">
                                <h4 class="tp-team-title-sm"><a href="/#">Manisha Tripathi</a></h4>
                                <span>Founder & CTO</span>
                             </div>
                             <div class="tp-team-social">
                                <a class="icon-color-1" href="/#">
                                   <i class="fab fa-facebook-f"></i>
                                   <span></span>
                                </a>
                                <a class="icon-color-2" href="/#">
                                   <i class="fab fa-instagram"></i>
                                   <span></span>
                                </a>
                                <a class="icon-color-3" href="/#">
                                   <i class="fab fa-twitter"></i>
                                   <span></span>
                                </a>
                                <a class="icon-color-4" href="/#">
                                   <i class="fab fa-linkedin-in"></i>
                                   <span></span>
                                </a>
                             </div>
                          </div>
                       </div>
                       <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-50 tp-team-border-right tp-border-after-2 team-inner-border-right">
                          <div class="tp-team-item tp-team-inner-title-color text-center z-index">
                             <div class="tp-team-img">
                                <img src="assets/img/team/team-4-2.png" alt=""/>
                             </div>
                             <div class="tp-team-content">
                                <h4 class="tp-team-title-sm"><a href="/#">Priyanka Nedariya</a></h4>
                                <span>Technical Lead</span>
                             </div>
                             <div class="tp-team-social">
                                <a class="icon-color-1" href="/#">
                                   <i class="fab fa-facebook-f"></i>
                                   <span></span>
                                </a>
                                <a class="icon-color-2" href="/#">
                                   <i class="fab fa-instagram"></i>
                                   <span></span>
                                </a>
                                <a class="icon-color-3" href="/#">
                                   <i class="fab fa-twitter"></i>
                                   <span></span>
                                </a>
                                <a class="icon-color-4" href="/#">
                                   <i class="fab fa-linkedin-in"></i>
                                   <span></span>
                                </a>
                             </div>
                          </div>
                       </div>
                       <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-50 tp-team-border-right tp-border-after-3 team-inner-border-right">
                          <div class="tp-team-item tp-team-inner-title-color text-center z-index">
                             <div class="tp-team-img">
                                <img src="assets/img/team/team-4-3.png" alt=""/>
                             </div>
                             <div class="tp-team-content">
                                <h4 class="tp-team-title-sm"><a href="/#">Jigar Parmar</a></h4>
                                <span>CEO & Founder</span>
                             </div>
                             <div class="tp-team-social">
                                <a class="icon-color-1" href="/#">
                                   <i class="fab fa-facebook-f"></i>
                                   <span></span>
                                </a>
                                <a class="icon-color-2" href="/#">
                                   <i class="fab fa-instagram"></i>
                                   <span></span>
                                </a>
                                <a class="icon-color-3" href="/#">
                                   <i class="fab fa-twitter"></i>
                                   <span></span>
                                </a>
                                <a class="icon-color-4" href="/#">
                                   <i class="fab fa-linkedin-in"></i>
                                   <span></span>
                                </a>
                             </div>
                          </div>
                       </div>
                       {/* <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-50 tp-team-border-right tp-border-after-4 team-inner-border-right">
                          <div class="tp-team-item tp-team-inner-title-color text-center z-index">
                             <div class="tp-team-img">
                                <img src="assets/img/team/team-4-4.png" alt=""/>
                             </div>
                             <div class="tp-team-content">
                                <h4 class="tp-team-title-sm"><a href="/#">Imdat Cimsit</a></h4>
                                <span>PHP Developer</span>
                             </div>
                             <div class="tp-team-social">
                                <a class="icon-color-1" href="/#">
                                   <i class="fab fa-facebook-f"></i>
                                   <span></span>
                                </a>
                                <a class="icon-color-2" href="/#">
                                   <i class="fab fa-instagram"></i>
                                   <span></span>
                                </a>
                                <a class="icon-color-3" href="/#">
                                   <i class="fab fa-twitter"></i>
                                   <span></span>
                                </a>
                                <a class="icon-color-4" href="/#">
                                   <i class="fab fa-linkedin-in"></i>
                                   <span></span>
                                </a>
                             </div>
                          </div>
                       </div> */}
                    </div>
                 </div>
              </div>
              {/* <!-- tp-team-area-end --> */}
  
              {/* <!-- tp-journey-area-start --> */}
              {/* <!-- tp-journey-area-end --> */}
  
              {/* <!-- tp-job-area-start --> */}
              {/* <!-- tp-job-area-end --> */}
  
              {/* <!-- tp-cta-area-start --> */}
              <div class="tp-cta-area p-relative">
                 <div class="tp-cta-grey-bg grey-bg-2"></div>
                 <div class="container">
                    <div class="row">
                       <div class="col-12">
                          <div class="tp-cta-bg" 
                          style={{backgroundImage: `url("assets/img/cta/cta-bg.jpg")`}}>
                             <div class="tp-cta-content tp-inner-font text-center">
                                <h3 class="tp-section-title text-white">Try our service now!</h3>
                                <p>Eyerything you need to accept cord payments and grow your business <br/>
                                   anywhere on the planet.</p>
                                   <a class="tp-btn-inner white-bg text-black" href="/#">
                                    Get Started Now</a>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
              {/* <!-- tp-cta-area-end --> */}
  
           </main>
           <Footer1/>
         </div>
   </div>
</>
    );
}
