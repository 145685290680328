import React,{ useState } from "react";  
import Header1 from "../../Common/Header1";
import Footer1 from "../../Common/Footer1";
import ReactDOM from 'react-dom';
import { useFormik ,ErrorMessage  } from "formik";
import * as Yup from "yup";
//import 'bootstrap/dist/css/bootstrap.min.css'; 
import { 
    Button, Modal, ModalFooter, 
    ModalHeader, ModalBody 
} from "reactstrap"
import InquireNow from "../../ModalPopUp/InquireNow";

export default function Projects() 
{
       // Modal open state 
       const [modalInquiryNow, setModalInquiryNow] = useState(false); 
       const [modalData, set_Modal_Data] = useState(""); 
	  
       // Toggle for Modal 
       const toggleInquiryNow = () => setModalInquiryNow(!modalInquiryNow); 
   
   const phoneRegExp= /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

   const [open, setOpen] = React.useState(false);
   const handleClose = () => {
      setOpen(false);
  };
  const onFormSubmit = (e) => {
   e.preventDefault();
   handleClose();
 };

  const openModalHandle = (str) => () => { 
   set_Modal_Data(str); 
  // modalShow();
  setModalInquiryNow(!modalInquiryNow);
}; 

  const formik = useFormik({
   initialValues: {
      name: '',
      email: '',
      phone: '',
      message: '',
    },

    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, 'Must be 2 characters or More')
        .max(25)
        .required('Please Enter Name'),
      
      email: Yup.string()
         .email('Invalid email address')
         .required('Please Enter Email'),
      phone: Yup.string()
        .max(10, 'Must be 10 characters')
        .matches(phoneRegExp,"Phone number is not valid")
        .required('Please Enter Phone'),
      message: Yup.string()
        .min(3, 'Must be 3 characters or More')
        
        .required('Please Enter Message'),
    }),

    onSubmit: values => {
      // alert(JSON.stringify(values.name, null, 2));
      // alert(JSON.stringify(values.email, null, 2));
      // alert(JSON.stringify(values.phone, null, 2));
      // alert(JSON.stringify(values.message, null, 2));
    },
  });
    return (
        <>
        
        <Header1/>
        <main>

            {/* <!-- breadcrumb-area-start --> */}
            <div className="breadcrumb__area breadcrumb-height-2 breadcrumb-overlay p-relative fix"

             data-background="assets/img/project/project-brdcrmb-bg.jpg">
               <div className="breadcrumb__shape-2 z-index-4">
                  <img src="assets/img/breadcrumb/breadcrumb-shape-2-2.png" alt=""/>
               </div>
               <div className="breadcrumb__shape-3 z-index-4">
                  <img src="assets/img/breadcrumb/breadcrumb-sub-2.png" alt=""/>
               </div>
               <div className="breadcrumb__shape-4 z-index-4">
                  <img src="assets/img/breadcrumb/breadcrumb-sub-3.png" alt=""/>
               </div>
               <div className="container">
                  <div className="row justify-content-center">
                     <div className="col-xl-10">
                        <div className="breadcrumb__content z-index-3 text-center">
                           <h3 className="breadcrumb__title tp-char-animation text-black">Our Projects</h3>
                           <div className="breadcrumb__text wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".6s">
                              <p>We have an experienced team of production and inspection personnel <br/> to ensure
                                 quality.</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {/* <!-- breadcrumb-area-end --> */}

            {/* <!--Portfolio Start--> */}
            <div className="portfolio-area pt-100 pb-90">
               <div className="container">
                  {/* <div class="row">
                     <div class="col-xl-12">
                        <div class="portfolio-filter masonary-menu text-center mb-35">
                           <button data-filter="*" class="active"><span>All</span></button>
                           <button data-filter=".cat1"><span>Design Work</span></button>
                           <button data-filter=".cat2"><span>Branding</span></button>
                           <button data-filter=".cat3"><span>Development</span></button>
                           <button data-filter=".cat4"><span>Illusutration</span></button>
                           <button data-filter=".cat5"><span>App Design</span></button>
                        </div>
                     </div>
                  </div> */}
                  <div className="row grid">
                     <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 grid-item  cat1 cat4 cat3 cat5">
                        <div className="inner-project-item mb-30">
                           <div className="inner-project-img fix p-relative">
                              <img className="w-100" src="assets/img/project/project-inner-1.jpg" alt=""/>
                              <div className="inner-project-brand">
                                 <img src="assets/img/project/project-inner-brand-1.png" alt=""/>
                              </div>
                           </div>
                           <div className="inner-project-content">
                              <span className="inner-project-category-title"></span>
                              <h4 className="inner-project-titleAirbnb"><a href=""> TravCRM</a></h4>
                              
                              <p></p>
                                 <a class="tp-btn-inner text-white" href="#" onClick={openModalHandle("TravCRM")}>Inquire Now</a>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 grid-item  cat4 cat2 cat1 ">
                        <div className="inner-project-item  mb-30">
                           <div className="inner-project-img fix p-relative">
                              <img src="assets/img/project/project-inner-2.jpg" alt=""/>
                              <div className="inner-project-brand">
                                 <img className="w-100" src="assets/img/project/project-inner-brand-2.png" alt=""/>
                              </div>
                           </div>
                           <div className="inner-project-content">
                              <span className="inner-project-category-title"></span>
                              <h4 className="inner-project-title"><a href="" >B2BTrav</a></h4>
                              <p></p>
                                 <a className="tp-btn-inner text-white" href="#" onClick={openModalHandle("B2BTrav")}>Inquire Now</a>
                           
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 grid-item  cat3 cat4 cat5 ">
                        <div className="inner-project-item mb-30">
                           <div className="inner-project-img fix p-relative">
                              <img className="w-100" src="assets/img/project/project-inner-3.jpg" alt=""/>
                              <div className="inner-project-brand">
                                 <img src="assets/img/project/project-inner-brand-3.png" alt=""/>
                              </div>
                           </div>
                           <div className="inner-project-content">
                              <span className="inner-project-category-title"></span>
                              <h4 className="inner-project-title"><a href="#" >TravelNinjaz</a></h4>
                              <p></p>
                                 <a className="tp-btn-inner text-white" href="#" onClick={openModalHandle("TravelNinjaz")}>Inquire Now</a>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 grid-item  cat3 cat4 cat5 ">
                        <div className="inner-project-item mb-30">
                           <div className="inner-project-img fix p-relative">
                              <img className="w-100" src="assets/img/project/project-inner-3.jpg" alt=""/>
                              <div className="inner-project-brand">
                                 <img src="assets/img/project/project-inner-brand-3.png" alt=""/>
                              </div>
                           </div>
                           <div className="inner-project-content">
                              <span className="inner-project-category-title"></span>
                              <h4 className="inner-project-title"><a href="#" >Aahvan</a></h4>
                              <p></p>
                                 <a className="tp-btn-inner text-white" href="#" onClick={openModalHandle("Aahvan")}>Inquire Now</a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {/* <!--Portfolio End-->
 */}
            {/* <!-- tp-cta-area-start --> */}
            <div className="tp-cta-area p-relative">
               <div className="tp-cta-grey-bg grey-bg-2"></div>
               <div className="container">
                  <div className="row">
                     <div className="col-12">
                        <div className="tp-cta-bg" style={{backgroundImage:'url("assets/img/cta/cta-bg.jpg")'}}
                       >
                           <div className="tp-cta-content tp-inner-font text-center">
                              <h3 className="tp-section-title text-white">Try our service now!</h3>
                              <p>Eyerything you need to accept cord payments and grow your business <br/>
                                 anywhere on the planet.</p>
                              <a className="tp-btn-inner white-bg text-black" href="/#">Get Started Now</a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {/* <!-- tp-cta-area-end --> */}
            <Modal isOpen={modalInquiryNow} toggle={toggleInquiryNow}>
                 
                <ModalHeader toggle={toggleInquiryNow}> 
                Inquiry For {modalData}</ModalHeader> 
            <ModalBody> 
                <InquireNow
                Project={{ name: {modalData}}}
                setModalInquiryNow = {setModalInquiryNow}
                />
            </ModalBody> 
             {/* <ModalFooter> 
                 <Button color="primary" onClick={toggleInquiryNow} >Okay</Button>  
            </ModalFooter>   */}

            </Modal> 
        
         </main>
                    

    
         <Footer1/>
        
         </>
    );
}
