import Footer1 from "../../Common/Footer1";
import Header1 from "../../Common/Header1";
import JsonData from "../../../JSONData/CareerData.json";
import React, { useEffect, useState } from "react";

export default function Career() {

    const [openPositions, setOpenPositions] = useState([]);

    useEffect(() => {
        setOpenPositions(JsonData);
    }, []);

    return (<>

        <Header1 />
        <div id="smooth-wrapper">
            <div id="smooth-content">
                <main>

                    {/* <!-- breadcrumb-area-start --> */}
                    <div className="breadcrumb__area breadcrumb-height-2 breadcrumb-overlay p-relative fix">
                        <div className="breadcrumb__shape-1">
                            <img src="assets/img/breadcrumb/breadcrumb-shape-1.png" alt="" />
                        </div>
                        <div className="breadcrumb__shape-2">
                            <img src="assets/img/breadcrumb/breadcrumb-shape-2.png" alt="" />
                        </div>
                        <div className="container">
                            <div className="breadcrumb__content-wrap">
                                <div className="row justify-content-center">
                                    <div className="col-xl-12">
                                        <div className="breadcrumb__content text-center z-index-3 mb-60">
                                            <h3 className="breadcrumb__title">Build Your Career With
                                             <br /> Our Team</h3>
                                            <div className="breadcrumb__text">
                                                <p>Work without distraction on your own or with your team.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- breadcrumb-area-end --> */}
                    {/* <!-- carrer-banner-area-start --> 
                    <div className="carrer-banner-area carrer-banner-space">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xl-3 col-lg-3 col-md-6">
                                    <div className="carrer-banner-img-item">
                                        <img src="assets/img/career/image-1.png" alt="" />
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6">
                                    <div className="carrer-banner-img-item parallax-main">
                                            data-parallax='{"y": 300, "smoothness": 10}' />
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 d-none d-lg-block">
                                    <div className="carrer-banner-img-item mb-20">
                                        <img src="assets/img/career/image-3.png" alt="" />
                                    </div>
                                    <div className="carrer-banner-img-item">
                                        <img src="assets/img/career/image-4.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- carrer-banner-area-end -->*/}
                    {/* <!-- tp-platform-area-start --> 
                    <div className="tp-platform-area tp-platform-inner pt-120 p-relative z-index">
                        <div className="container">
                            <div className="row align-items-end mb-60">
                                <div className="col-xl-7 col-lg-6 wow tpfadeLeft" data-wow-duration=".9s" data-wow-delay=".3s">
                                    <div className="tp-platform-section-box">
                                        <h5 className="inner-section-subtitle pb-10">What we offer</h5>
                                        <h3 className="tp-section-title">Our Platform</h3>
                                    </div>
                                </div>
                                <div className="col-xl-5 col-lg-6  wow tpfadeRight" data-wow-duration=".9s" data-wow-delay=".5s">
                                    <div className="tp-platform-text">
                                        <p>Explore how Avalance can help you toassess security
                                            controls continuously.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-xl-2 col-lg-2 d-none d-xl-block">
                                    <div className="tp-platform-img-box">
                                        <img src="assets/img/payment/platform-1.png" alt="" />
                                    </div>
                                </div>
                                <div className="col-xl-10 col-lg-12">
                                    <div className="row-custom-wrapper">
                                        <div className="row-custom">
                                            <div className="col-custom">
                                                <div className="tp-panel-item">
                                                    <div className="tp-panel-content">
                                                        <span>01.</span>
                                                        <h4 className="tp-panel-title child-one">Firewall & VPN</h4>
                                                    </div>
                                                </div>
                                                <div className="tp-panel-item-2">
                                                    <div className="tp-panel-content-2">
                                                        <span>01.</span>
                                                        <h4 className="tp-panel-title-2">Live Security</h4>
                                                        <p>Blocks infected website tracking
                                                            programs and annoying.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-custom">
                                                <div className="tp-panel-item">
                                                    <div className="tp-panel-content">
                                                        <span>02.</span>
                                                        <h4 className="tp-panel-title child-two">Hack Protection</h4>
                                                    </div>
                                                </div>
                                                <div className="tp-panel-item-2">
                                                    <div className="tp-panel-content-2">
                                                        <span>02.</span>
                                                        <h4 className="tp-panel-title-2">Hack Protection</h4>
                                                        <p>Blocks infected website tracking
                                                            programs and annoying.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-custom active">
                                                <div className="tp-panel-item">
                                                    <div className="tp-panel-content">
                                                        <span>03.</span>
                                                        <h4 className="tp-panel-title child-three">Live Security</h4>
                                                    </div>
                                                </div>
                                                <div className="tp-panel-item-2">
                                                    <div className="tp-panel-content-2">
                                                        <span>03.</span>
                                                        <h4 className="tp-panel-title-2">Live Security</h4>
                                                        <p>Blocks infected website tracking
                                                            programs and annoying.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-custom">
                                                <div className="tp-panel-item">
                                                    <div className="tp-panel-content">
                                                        <span>04.</span>
                                                        <h4 className="tp-panel-title child-four">Online Support</h4>
                                                    </div>
                                                </div>
                                                <div className="tp-panel-item-2">
                                                    <div className="tp-panel-content-2">
                                                        <span>04.</span>
                                                        <h4 className="tp-panel-title-2">Online Support</h4>
                                                        <p>Blocks infected website tracking
                                                            programs and annoying.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>*/}
                    {/* <!-- tp-platform-area-end --> */}
                    {/* <!-- tp-job-area-start --> */}
                    <div className="job-area pt-120 pb-120">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="job-section-box text-center mb-40">
                                        <h3 className="tp-section-title">Open Positions</h3>
                                        <p>
                                            <br />
                                            </p>
                                    </div>
                                </div>
                            </div>
                            {openPositions.map(op => (

                                <div className="job-post-box">
                                    <div className="row align-items-center">
                                        <div className="col-lg-5 col-md-4">
                                            <div className="job-post-info d-flex justify-content-start align-items-center">
                                                <div className="job-post-category">
                                                    <span>{op.JobTitle}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-5 col-md-5">
                                            <div className="job-post-wrapper d-flex align-items-center">
                                                <div className="job-post-time d-flex align-items-center">
                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9 16.75C13.2802 16.75 16.75 13.2802 16.75 9C16.75 4.71979 13.2802 1.25 9 1.25C4.71979 1.25 1.25 4.71979 1.25 9C1.25 13.2802 4.71979 16.75 9 16.75Z" stroke="#5F6168" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M9 5.7998V9.9998L11.8 11.3998" stroke="#5F6168" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                    <span>{op.JobType}</span>
                                                </div>
                                                <div className="job-post-location d-flex align-items-center">
                                                    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1 7.51463C1 3.9167 4.13401 1 8 1C11.866 1 15 3.9167 15 7.51463C15 11.0844 12.7658 15.2499 9.28007 16.7396C8.46748 17.0868 7.53252 17.0868 6.71993 16.7396C3.23416 15.2499 1 11.0844 1 7.51463Z" stroke="#5F6168" strokeWidth="1.5" />
                                                        <path d="M10 8C10 9.10457 9.10457 10 8 10C6.89543 10 6 9.10457 6 8C6 6.89543 6.89543 6 8 6C9.10457 6 10 6.89543 10 8Z" stroke="#5F6168" strokeWidth="1.5" />
                                                    </svg>
                                                    <span>{op.JobLocation}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-3">
                                            <div className="job-post-apply-btn text-start text-md-end">
                                                <a className="tp-btn-inner tp-btn-hover alt-color-orange" href={"/CareerDetails?jobId="+ op.id}><span>Apply</span> <b></b></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            ))}

                            {/* <div className="job-post-box">
                                <div className="row align-items-center">
                                    <div className="col-lg-5 col-md-4">
                                        <div className="job-post-info d-flex justify-content-start align-items-center">
                                            <div className="job-post-category">
                                                <span>Product Designer</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-5">
                                        <div className="job-post-wrapper d-flex align-items-center">
                                            <div className="job-post-time d-flex align-items-center">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9 16.75C13.2802 16.75 16.75 13.2802 16.75 9C16.75 4.71979 13.2802 1.25 9 1.25C4.71979 1.25 1.25 4.71979 1.25 9C1.25 13.2802 4.71979 16.75 9 16.75Z" stroke="#5F6168" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M9 5.7998V9.9998L11.8 11.3998" stroke="#5F6168" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                                <span>Full-time</span>
                                            </div>
                                            <div className="job-post-location d-flex align-items-center">
                                                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 7.51463C1 3.9167 4.13401 1 8 1C11.866 1 15 3.9167 15 7.51463C15 11.0844 12.7658 15.2499 9.28007 16.7396C8.46748 17.0868 7.53252 17.0868 6.71993 16.7396C3.23416 15.2499 1 11.0844 1 7.51463Z" stroke="#5F6168" strokeWidth="1.5" />
                                                    <path d="M10 8C10 9.10457 9.10457 10 8 10C6.89543 10 6 9.10457 6 8C6 6.89543 6.89543 6 8 6C9.10457 6 10 6.89543 10 8Z" stroke="#5F6168" strokeWidth="1.5" />
                                                </svg>
                                                <span>Newark, NJ</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                        <div className="job-post-apply-btn text-start text-md-end">
                                            <a className="tp-btn-inner tp-btn-hover alt-color-orange" href="career-details.html"><span>Apply</span> <b></b></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="job-post-box">
                                <div className="row align-items-center">
                                    <div className=" col-lg-5 col-md-4">
                                        <div className="job-post-info d-flex justify-content-start align-items-center">
                                            <div className="job-post-category">
                                                <span>Software Engineer</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" col-lg-4 col-md-5">
                                        <div className="job-post-wrapper d-flex align-items-center">
                                            <div className="job-post-time d-flex align-items-center">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9 16.75C13.2802 16.75 16.75 13.2802 16.75 9C16.75 4.71979 13.2802 1.25 9 1.25C4.71979 1.25 1.25 4.71979 1.25 9C1.25 13.2802 4.71979 16.75 9 16.75Z" stroke="#5F6168" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M9 5.7998V9.9998L11.8 11.3998" stroke="#5F6168" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                                <span>Full-time</span>
                                            </div>
                                            <div className="job-post-location d-flex align-items-center">
                                                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 7.51463C1 3.9167 4.13401 1 8 1C11.866 1 15 3.9167 15 7.51463C15 11.0844 12.7658 15.2499 9.28007 16.7396C8.46748 17.0868 7.53252 17.0868 6.71993 16.7396C3.23416 15.2499 1 11.0844 1 7.51463Z" stroke="#5F6168" strokeWidth="1.5" />
                                                    <path d="M10 8C10 9.10457 9.10457 10 8 10C6.89543 10 6 9.10457 6 8C6 6.89543 6.89543 6 8 6C9.10457 6 10 6.89543 10 8Z" stroke="#5F6168" strokeWidth="1.5" />
                                                </svg>
                                                <span>London, UK</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" col-lg-3 col-md-3">
                                        <div className="job-post-apply-btn text-start text-md-end">
                                            <a className="tp-btn-inner tp-btn-hover alt-color-orange" href="career-details.html"><span>Apply</span> <b></b></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="job-post-box">
                                <div className="row align-items-center">
                                    <div className=" col-lg-5 col-md-4">
                                        <div className="job-post-info d-flex justify-content-start align-items-center">
                                            <div className="job-post-category">
                                                <span>Junior Frontend Developer</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" col-lg-4 col-md-5">
                                        <div className="job-post-wrapper d-flex align-items-center">
                                            <div className="job-post-time d-flex align-items-center">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9 16.75C13.2802 16.75 16.75 13.2802 16.75 9C16.75 4.71979 13.2802 1.25 9 1.25C4.71979 1.25 1.25 4.71979 1.25 9C1.25 13.2802 4.71979 16.75 9 16.75Z" stroke="#5F6168" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M9 5.7998V9.9998L11.8 11.3998" stroke="#5F6168" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                                <span>Full-time</span>
                                            </div>
                                            <div className="job-post-location d-flex align-items-center">
                                                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 7.51463C1 3.9167 4.13401 1 8 1C11.866 1 15 3.9167 15 7.51463C15 11.0844 12.7658 15.2499 9.28007 16.7396C8.46748 17.0868 7.53252 17.0868 6.71993 16.7396C3.23416 15.2499 1 11.0844 1 7.51463Z" stroke="#5F6168" strokeWidth="1.5" />
                                                    <path d="M10 8C10 9.10457 9.10457 10 8 10C6.89543 10 6 9.10457 6 8C6 6.89543 6.89543 6 8 6C9.10457 6 10 6.89543 10 8Z" stroke="#5F6168" strokeWidth="1.5" />
                                                </svg>
                                                <span>Brooklyn, NY</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" col-lg-3 col-md-3">
                                        <div className="job-post-apply-btn text-start text-md-end">
                                            <a className="tp-btn-inner tp-btn-hover alt-color-orange" href="career-details.html"><span>Apply</span> <b></b></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="job-post-box">
                                <div className="row align-items-center">
                                    <div className=" col-lg-5 col-md-4">
                                        <div className="job-post-info d-flex justify-content-start align-items-center">
                                            <div className="job-post-category">
                                                <span>Finance Executive</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" col-lg-4 col-md-5">
                                        <div className="job-post-wrapper d-flex align-items-center">
                                            <div className="job-post-time d-flex align-items-center">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9 16.75C13.2802 16.75 16.75 13.2802 16.75 9C16.75 4.71979 13.2802 1.25 9 1.25C4.71979 1.25 1.25 4.71979 1.25 9C1.25 13.2802 4.71979 16.75 9 16.75Z" stroke="#5F6168" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M9 5.7998V9.9998L11.8 11.3998" stroke="#5F6168" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                                <span>Full-time</span>
                                            </div>
                                            <div className="job-post-location d-flex align-items-center">
                                                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 7.51463C1 3.9167 4.13401 1 8 1C11.866 1 15 3.9167 15 7.51463C15 11.0844 12.7658 15.2499 9.28007 16.7396C8.46748 17.0868 7.53252 17.0868 6.71993 16.7396C3.23416 15.2499 1 11.0844 1 7.51463Z" stroke="#5F6168" strokeWidth="1.5" />
                                                    <path d="M10 8C10 9.10457 9.10457 10 8 10C6.89543 10 6 9.10457 6 8C6 6.89543 6.89543 6 8 6C9.10457 6 10 6.89543 10 8Z" stroke="#5F6168" strokeWidth="1.5" />
                                                </svg>
                                                <span>Newark, NJ</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" col-lg-3 col-md-3">
                                        <div className="job-post-apply-btn text-start text-md-end">
                                            <a className="tp-btn-inner tp-btn-hover alt-color-orange" href="career-details.html"><span>Apply</span> <b></b></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="job-post-box">
                                <div className="row align-items-center">
                                    <div className=" col-lg-5 col-md-4">
                                        <div className="job-post-info d-flex justify-content-start align-items-center">
                                            <div className="job-post-category">
                                                <span>Junior Copywriter</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" col-lg-4 col-md-5">
                                        <div className="job-post-wrapper d-flex align-items-center">
                                            <div className="job-post-time d-flex align-items-center">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9 16.75C13.2802 16.75 16.75 13.2802 16.75 9C16.75 4.71979 13.2802 1.25 9 1.25C4.71979 1.25 1.25 4.71979 1.25 9C1.25 13.2802 4.71979 16.75 9 16.75Z" stroke="#5F6168" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M9 5.7998V9.9998L11.8 11.3998" stroke="#5F6168" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                                <span>Full-time</span>
                                            </div>
                                            <div className="job-post-location d-flex align-items-center">
                                                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 7.51463C1 3.9167 4.13401 1 8 1C11.866 1 15 3.9167 15 7.51463C15 11.0844 12.7658 15.2499 9.28007 16.7396C8.46748 17.0868 7.53252 17.0868 6.71993 16.7396C3.23416 15.2499 1 11.0844 1 7.51463Z" stroke="#5F6168" strokeWidth="1.5" />
                                                    <path d="M10 8C10 9.10457 9.10457 10 8 10C6.89543 10 6 9.10457 6 8C6 6.89543 6.89543 6 8 6C9.10457 6 10 6.89543 10 8Z" stroke="#5F6168" strokeWidth="1.5" />
                                                </svg>
                                                <span>London, UK</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" col-lg-3 col-md-3">
                                        <div className="job-post-apply-btn text-start text-md-end">
                                            <a className="tp-btn-inner tp-btn-hover alt-color-orange" href="career-details.html"><span>Apply</span> <b></b></a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>

                    {/* <!-- tp-job-area-end --> */}
                    {/* <!-- tp-cta-area-start --> */}
                    <div className="tp-cta-area p-relative">
                        <div className="tp-cta-grey-bg grey-bg-2"></div>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="tp-cta-bg" style={{ backgroundImage: 'url("assets/img/cta/cta-bg.jpg")' }}
                                    >
                                        <div className="tp-cta-content tp-inner-font text-center">
                                            <h3 className="tp-section-title text-white">Try our service now!</h3>
                                            <p>Eyerything you need to accept cord payments and grow your business <br />
                                                anywhere on the planet.</p>
                                            <a className="tp-btn-inner white-bg text-black" href="/#">Get Started Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- tp-cta-area-end --> */}


                </main>



                <Footer1 />
            </div>
        </div>






    </>)
} 