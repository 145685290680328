import React, { useState } from "react";  
import Footer1 from "../../Common/Footer1";
import Header1 from "../../Common/Header1";
import { contactEmail, contactNumber } from "../../../Constants/const";
import { useFormik ,ErrorMessage  } from "formik";
import * as Yup from "yup";
import { LoadingOutlined } from "@ant-design/icons";
import {
   inquiry,
 } from "../../../Services/GlobalMasters/contactService";
 import { 
   Button, Modal, ModalFooter, 
   ModalHeader, ModalBody 
} from "reactstrap"
import ResponseStatus from "../../Common/ResponseStatus";

export default function Contact() {

   const [resHeader, setResHeader] = useState();
   const [resMessage, setResMessage] = useState();
  
   // Modal open state 
   const [modal, setModal] = React.useState(false); 
  
   // Toggle for Modal 
   const toggle = (str) => setModal(!modal); 
   const [open, setOpen] = React.useState(false);
   const handleClose = () => {
      setOpen(false);
  };


   const initialValues ={
      name: "",
      email: "",
      phone: "",
      message: "",
   
   };
   const phoneRegExp= /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
   const [errorMessage, setErrorMessage] = useState(undefined);
   const [isLoading, setIsLoading] = useState(false);
   
   const UserInquiry = async (user,{resetForm}) => {
    setIsLoading(true);
    try {
      const response = await inquiry({
       Contact_name: user.name,
       Email: user.email,
       Phone: user.phone.toString(),
       Message: user.message,
       Project: "",
        // Company_name: user.companyname,
        
       // UserName: user.email,
        // Company_gst_no: user.gstno,
       // Password: user.password,
     //   ConfirmPassword: user.confirmpassword,
       // Role: "Admin",
        
        //Operation: "ADD",
        //Social_media_type: "APPLICATION",
       // ApplicationType: applicationType,
        //Row_created_by: `${user.name} `,
       // Row_altered_by: `${user.name} `,
      });

      if (response?.status === "SUCCESS") {
        /**This is for save company details */
         setErrorMessage(undefined);
          resetForm({ user: ''});
          setResHeader("SUCCESS");
       
          setResMessage("Your Inquiry has been sent successfully. Our team will  reachout to you soon. Thank you!");
          toggle();
       
      } else {
         setErrorMessage(response?.message);
          setResHeader("ERROR");
          setResMessage(response?.message);
          toggle();
    }
    } catch (error) {
      setErrorMessage(error);
      setResHeader("ERROR");
      setResMessage(error);
      toggle();
} finally {
      setIsLoading(false);
    }
  };
 
    
  const formik = useFormik({
   initialValues: {
      name: '',
      email: '',
      phone: '',
      message: '',
    },

    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, 'Must be 2 characters or More')
        .max(25)
        .required('Please Enter Name'),
      
      email: Yup.string()
         .email('Invalid email address')
         .required('Please Enter Email'),
      phone: Yup.string()
      //   .max(10, 'Must be 10 characters')
        .matches(phoneRegExp,"Phone number is not valid")
        .required('Please Enter Phone'),
      message: Yup.string()
        .min(3, 'Must be 3 characters or More')
        
        .required('Please Enter Message'),
    }),

    onSubmit: (values ,{resetForm})=> {
      // alert(JSON.stringify(values.name, null, 2));
      // alert(JSON.stringify(values.email, null, 2));
      // alert(JSON.stringify(values.phone, null, 2));
      // alert(JSON.stringify(values.message, null, 2));
      UserInquiry(values,{resetForm});
      
    },
  }); 
 const mystyle =  {
      color: "#270",
      /*backgroundColor: "#DFF2BF"*/
    };
   
    return (
      <>
      <Header1/>
        <div id="smooth-wrapper">
        <div id="smooth-content">
  
        <main>

         {/* <!-- breadcrumb-area-start --> */}
         <div className="breadcrumb__area breadcrumb-height-2 breadcrumb-overlay p-relative fix"

data-background="assets/img/project/project-brdcrmb-bg.jpg">
  <div className="breadcrumb__shape-2 z-index-4">
     <img src="assets/img/breadcrumb/breadcrumb-shape-2-2.png" alt=""/>
  </div>
  <div className="breadcrumb__shape-3 z-index-4">
     <img src="assets/img/breadcrumb/breadcrumb-sub-2.png" alt=""/>
  </div>
  <div className="breadcrumb__shape-4 z-index-4">
     <img src="assets/img/breadcrumb/breadcrumb-sub-3.png" alt=""/>
  </div>
  <div className="container">
     <div className="row justify-content-center">
        <div className="col-xl-10">
           <div className="breadcrumb__content z-index-3 text-center">
              <h3 className="breadcrumb__title tp-char-animation text-black">Get InTouch</h3>
              <div className="breadcrumb__text wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".6s">
                 <p>We have an experienced team of production and inspection personnel <br/> to ensure
                    quality.</p>
              </div>
           </div>
        </div>
     </div>
  </div>
</div>
{/* <!-- breadcrumb-area-end --> */}

        {/* <!-- tp-contact-area-strat --> */}
        
        <div className="contact-form-area pt-50 pb-120">
           <div className="container">
              <div className="row gx-0">
                 <div className="col-xl-5 col-lg-6">
                    <div className="contact-form-left">
                       <div className="contact-form-section-box pb-80">
                          <h5 className="inner-section-subtitle">CONTACT US</h5>
                          <h4 className="tp-section-title pb-10">We'd love to <br/> hear From 
                          <span>
                             <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <rect width="44" height="44" fill="url(#pattern0)"/>
                                <defs>
                                <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                                <use xlinkHref="#image0_2106_35" transform="translate(-0.425532 -0.170213) scale(0.0212766)"/>
                                </pattern>
                                <image id="image0_2106_35" width="83" height="66" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFMAAABCCAYAAAArOOo+AAADcklEQVR4nO2bP0wTURzHP5RqQhyMtyJNTCcTnKCLnVhs4sRmHRk10U0WFhcXiQskuDPhxirRQZMagn8WB1gatTqQiMV/qRBM6nB99oDe9Y7+3mvf8T5j03u/yyfv97vvK8dQs9ls4hAh0+8bSBNOpiBOpiBOpiBOpiBOpiBOpiBOpiCnWma1AmvzUK/JrJeVWcYeqhWovoKnDw9/fu1e72ufCplhAhUXLsrUSa3MbgKD5IsyNVMlM4nAIF5Opr71Mk8qUFEoy92LlTJ7FRgkf7X3NRRDtvyeKSkwyKOvcmsN9M6UEuiNQf3z8c8lWxwGUKbkDvTGoHAzfC3JFocBkamjhfNFKC/Ag4no70jSN5m6ZiBAadY/0SxNR39PKhIpjMrUKRD8ti4v+jtubd6vF4b0vAQDMnULVOSLcHu1XbNbPel5CZqiUb0Gb57oF6hQba1qR81JhWQkUmjZmY+nO0cRaYJtrVi52/06HS0OmmTeWm239+sVHRUOt7Wi25z8f62GFgcDJ6B6TV5ssK0V1Ur3p7di7q38kxwMHyd7FduprdW6ceakQse8BBg6OPjbzGaH9aweQVKxndpasTQdr73Bn5flxfj3mYTMyxfv9KzcBS/nH/XKi37blRfCHwyl2XCRceekQte8BBg+N3Lp/tRUgTNn+3eyHDkPo1dg/LovdHTc/2zvB8wsh0uuVmDlTrJaM8u9328Y2efPNtjc/MDE5GV9VRLg5dq7Nop6Lf4DR6ErEiky29vfWF9/r7eKBuLkyaPobHFo/d18a+sjjcae3kqCJJ2TCulfiY6SAah92rZGZpxzdxg6smWQDMDOznd2d3/qrSRE0jmp0D0voSXzT2OP/f0D/dUEKM2e7Drd8xJaMqvVL/z+1dBfTYDJGye7rls6kMC6F7e8XPKWNdHiYKFMSP6SlYkWB0tlerlkMUd3JFJYKROglGB36o5ECmtl5ovxdpypeQkWywQoxHiym5qXYLnMWDvTQCRSWC3Ty0WHeJMtDpbLhOgQb7LFIQUyo0K8qUiksF4mhId4U5FIkQqZnUK86XkJKZEJx0O86XkJKZJ5NMSbjESK1MiEdojvR4tDymTmi61Xr/skcyBew5bCy8Fcf96pAFK2M/uNkymINf9UZQNuZwriZAriZAriZAriZAriZAriZAriZAryD4xxER8vOFEDAAAAAElFTkSuQmCC"/>
                                </defs>
                             </svg>
                          </span>
                          you.</h4>
                          <p>While we're good with smoke signals,
                             there <br/> are easier ways to get in touch.</p>
                       </div>
                       <div className="contact-form-social-box p-relative">
                          <div className="contact-form-social-item">
                             <a href="/#"><i className="fab fa-facebook-f"></i></a>
                             <a href="/#"><i className="fab fa-twitter"></i></a>
                             <a href="/#"><i className="fab fa-linkedin-in"></i></a>
                             <a href="/#"><i className="fab fa-instagram"></i></a>
                          </div>
                          <div className="contact-form-section-img">
                             <img src="assets/img/contact/contact-icon-sm-4.png" alt=""/>
                          </div>
                       </div>
                    </div>
                 </div>
                 <div className="col-xl-7 col-lg-6">
                    <div className="contact-form-right-warp">
                       <div className="postbox__comment-form">
                              <form 
                                 action="#" 
                                 onSubmit={(e) => {
                                    e.preventDefault();
                                    formik.handleSubmit();
                                    return false;
                                  }}
                                  className="box"                                 
                                >
                                 <div className="row gx-20">
                                    <div className="col-12 mb-35">
                                       <div className="postbox__comment-input mb-5">
                                          <input 
                                                type="text" 
                                                autoComplete="off" 
                                                name="name" 
                                                id="name"
                                              //  placeholder="Name" 
                                                value={formik.values.name} 
                                                onChange={formik.handleChange} 
                                                onBlur={formik.handleBlur} 
                                                className="inputText"   
                                                required                                              
                                          />
                                       <span className="floating-label">Full Name</span>
                                       </div>
                                       {formik.touched.name && formik.errors.name ? (
                                             <div className="inputError pl-20">{formik.errors.name}</div>
                                          ) : null}
                                       
                                    </div>
                                    <div className="col-12 mb-35">
                                       <div className="postbox__comment-input mb-5">
                                           <input 
                                                 type="text" 
                                                 autoComplete="off" 
                                                 name="email" 
                                                 id="email"
                                               //  placeholder="Your Email"
                                                 value={formik.values.email} 
                                                 onChange={formik.handleChange} 
                                                 onBlur={formik.handleBlur} 
                                                 className="inputText"
                                                 required
                                          />
                                          <span className="floating-label">Your Email</span>
                                       </div>
                                       {formik.touched.email && formik.errors.email ? (
                                           <div className="inputError pl-20">{formik.errors.email}</div>
                                                ) : null}
                                         
                                    </div>
                                    <div className="col-12 mb-35">
                                       <div className="postbox__comment-input mb-5">
                                          <input 
                                                type="text"
                                                autoComplete="off"
                                                name="phone"
                                                id="pone"
                                             //   placeholder="Phone Number" 
                                                value={formik.values.phone}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur} 
                                                className="inputText"
                                                 required
                                          />
                                          <span className="floating-label">Phone</span>
                                       </div>
                                       {formik.touched.phone && formik.errors.phone ? (
                                           <div className="inputError pl-20">{formik.errors.phone}</div>
                                                                     ) : null}
                                        
                                    </div>
                                    <div className="col-12 mb-5">
                                      </div>
                                    <div className="col-xxl-12">
                                       <div className="postbox__comment-input mb-5">
                                          <textarea className="textareaText" 
                                                autoComplete="off"
                                                name="message" 
                                                id="message"
                                            //    placeholder="Message..." 
                                                value={formik.values.message}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                required
                                                >

                                          </textarea>
                                          <span className="floating-label-2">Message</span>
                                       </div>
                                       {formik.touched.message && formik.errors.message ? (
                                           <div className="inputError pl-20">{formik.errors.message}</div>
                                                                     ) : null}
                                          
                                    </div>
                                    <div className="col-xxl-12 mt-15">
                                       <div className="postbox__btn-box">
                                          <button className="submit-btn w-100" type="submit">
										  {isLoading ? (
                                        <LoadingOutlined
                                        style={{
                                          fontSize: 24,
                                          marginRight: "5px",
                                        }}
                                        spin
                                      />
                                    ) : (
                                        <></>
                                    )} 
							  
										  Send your Request</button>
                                       </div>
                                    </div>
                                 </div>
                              </form>
                       </div>
                    </div>
                 </div>
              </div>
           </div>
        </div>

        <div className="contact-inner-area pb-130">
           <div className="container">
              <div className="row">
                 <div className="col-xl-12">
                    <div className="contact-inner-title-sm-wrap text-center mb-50">
                       <h4 className="contact-inner-title-sm">Book a Demo!</h4>
                       <p>We will contact again after receive your request in 24h</p>
                    </div>
                 </div>
              </div>
              <div className="contact-inner-wrapper">
                 <div className="row gx-0">
                    <div className="col-xl-4 col-lg-4">
                       <div className="contact-inner-item d-flex align-items-center justify-content-center">
                          <div className="contact-inner-img contact-img-1">
                             <img src="assets/img/contact/contact-icon-sm-1.png" alt=""/>
                          </div>
                          <div className="contact-inner-link">
                             <a href="/#">{contactEmail}</a>
                          </div>
                       </div>
                    </div>
                    <div className="col-xl-4 col-lg-4">
                       <div className="contact-inner-item d-flex align-items-center justify-content-center">
                          <div className="contact-inner-img contact-img-2">
                             <img src="assets/img/contact/contact-icon-sm-2.png" alt=""/>
                          </div>
                          <div className="contact-inner-link">
                             <a href="/#">{contactNumber}</a>
                          </div>
                       </div>
                    </div>
                    <div className="col-xl-4 col-lg-4">
                       <div className="contact-inner-item d-flex align-items-center justify-content-center">
                          <div className="contact-inner-img contact-img-3">
                             <img src="assets/img/contact/contact-icon-sm-3.png" alt=""/>
                          </div>
                          <div className="contact-inner-link">
                             <a href="#"
                              target="_blank">Ahmedabad, India</a>
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
           </div> 
        </div>
        {/* <!-- tp-contact-area-end --> */}
        <div className="contact-info-area pt-50 pb-30">
           <div className="container">
              <div className="row">
                 <div className="col-xl-12 col-lg-4 mb-30">
                    <div className="contact-info-item p-relative"> 
                       <div className="contact-info-badge">
                          <span>Main Office</span>
                       </div>
                       <div className="contact-info-img">
                          <img src="assets/img/contact/contact-icon-sm-6.png" alt=""/>
                       </div>
                       <div className="contact-info-title-box">
                          <h5 className="contact-info-title-sm"><a href="/#">India</a></h5>
                          <p>1208, Shivalik Shilp, 
                           Satellite,  <br/>
                           Ahmedabad, INDIA</p>
                       </div>
                    </div>
                 </div>
                </div>
           </div>
        </div>

        {/* <!-- tp-cta-area-start --> */}
        <div className="tp-cta-area p-relative">
           <div className="tp-cta-grey-bg grey-bg-2"></div>
           <div className="container">
              <div className="row">
                 <div className="col-12">
                    <div className="tp-cta-bg" style={{backgroundImage: `url("assets/img/cta/cta-bg.jpg")`}}
                    
                    >
                       <div className="tp-cta-content tp-inner-font text-center">
                          <h3 className="tp-section-title text-white">Try our service now!</h3>
                          <p>Eyerything you need to accept cord payments and grow your business <br/>
                             anywhere on the planet.</p>
                             <a className="tp-btn-inner white-bg text-black" href="/#">Get Started Now</a>
                       </div>
                    </div>
                 </div>
              </div>
           </div>
        </div>
        {/* <!-- tp-cta-area-end --> */}
        {/* <Modal isOpen={modal} toggle={toggle} centered={true}>
                 
                 <ModalHeader > 
				   <div  style={mystyle}>
                  <i class="fa fa-check"></i>
                               Success </div>
              
                 </ModalHeader>  
                       <ModalBody>
                         <div className="inner-project-content">
                            <p>  Your Inquiry has been sent successfully. Our team will  reachout to you soon. Thankyou!
                            </p>
                         </div>
                      </ModalBody> 
              <ModalFooter> 
                  <div class="contact-form-right-warp">
                  <div className="postbox__btn-box">
                     <button className="submit-btn w-100" type="submit" onClick={toggle}>Okay</button>
                  </div>
               </div>
            
             </ModalFooter>  
 
             </Modal>  */}
         <ResponseStatus
         Responsemodal={modal}
         Responsetoggle ={toggle}

        ResponseHeader = {resHeader}
        ResponseMessage = {resMessage}
        closeBothModals = {toggle}
        />

     </main>
     <Footer1/>
</div>
</div>
</>
    );
}
