 import { url } from "../../Constants/const";
import { get, post, post_file_upload } from "../../Services/Common/https";


/**This service for User inquiry*/
export const inquiry = (data) => post(`${url}/api/Contact/AddContact`, data);

export const careerinquiry = (data) => post(`${url}/api/Career/AddCareerContact`, data);

export const saveResumeFile = (data) => post_file_upload(`${url}/api/Career/SaveResumeFile`, data);

export const newsLetter = (data) => post(`${url}/api/Contact/AddNewsLetter`, data);


//   try {
//     const destination_json = JSON.stringify(destination);
//     const response = await axios.post(`${url}/api/Destination/save`, null, {
//       params: {
//         destination_json,
//         operation,
//       },
//   } catch (error) {
//     console.log(error);
//   }
// };
// export const SaveDestinationList = async (destination, operation) => {
//   const saveData = post(`${url}/api/Destination/save`, destination, operation);
// };






// export const downloadTemplate = (params) => {
//   const getdata = get(
//     `${url}/api/Destination/downloadTemplate?` + params
//   );
//   return getdata;
// };