/**base url*/

//Server 
export const url = "https://api.thedigitalogic.com"; //Web API for ADMIN


// LOCAL
 //export const url = "http://localhost:58054"; //Web API for ADMIN

export const applicationName= "THEDigitaLogic";

export const contactEmail = "hello@thedigitalogic.com";
export const contactNumber= "+91 9727788889";