import React, { useState, useEffect , useRef } from "react";  
import { useFormik ,ErrorMessage  } from "formik";
import * as Yup from "yup";

import { LoadingOutlined } from "@ant-design/icons";
import {
   careerinquiry,
   saveResumeFile
 } from "../../../Services/GlobalMasters/contactService";
 import { 
   Button, Modal, ModalFooter, 
   ModalHeader, ModalBody 
} from "reactstrap"
import CurrencyInput from 'react-currency-input-field';
//import "jquery-nice-select/css/style.css";
import ResponseStatus from "../../Common/ResponseStatus";

import $ from "jquery";
window.jQuery = window.$ = $;
require("jquery-nice-select");

export default function Careerform(props) {

   const [selectedYearsOfExperience, setSelectedYearsOfExperience] = useState(0);
  
   const [resHeader, setResHeader] = useState();
   const [resMessage, setResMessage] = useState();
  
   const options  = [
      {
        locale: 'en-IN',
        currency: 'INR',
      },
   ]
   const selectRef = useRef();

  useEffect(() => {
    $(selectRef.current).niceSelect();
  }, []);

  const getItem = (e) => {
   e.preventDefault();
   let selected = $(selectRef.current).val();
   alert(` You have selected ${selected}`);
 };

  // debugger;
  // alert("setJobPosition - " +  setJobPosition);
   // Modal open state 
    const [modal, setModal] = React.useState(false); 
    const [selectedFileName, setSelectedFileName] = React.useState(""); 
    // Toggle for Modal 
    const toggle = (str) => setModal(!modal); 

    const onFileChangeHandller = async (e) => {
      try {
        e.preventDefault();
         debugger;
        
        const formData = new FormData();
        let resumeFileName = e.target.files[0].name;    
        formData.append("file", e.target.files[0], resumeFileName);
        const response = await saveResumeFile(formData);

        setSelectedFileName(response.fileName);
       
      } catch (error) {
      
      }
    }

   
   const [value,setValue] = useState('');
   const UserInquiry = async (user,{resetForm}) => {
      setIsLoading(true);

      debugger;
      try {
        const response = await careerinquiry({
         name: user.name,
         Email: user.email,
         Phone: user.phone.toString(),
         Address: user.address,
         LinkedIn: user.linkedin,
         CurrentSalary: user.currentsalary,
         Experience:user.experience,
         ExpectedSalary: user.expectedsalary,
         Personalnote: user.note,
         FileName: selectedFileName,
         Position:user.hiddenField
       //  FileName:"tmpfile.doc"
       //  Message: user.message,
       //  Project: "",
          // Company_name: user.companyname,
          
         // UserName: user.email,
          // Company_gst_no: user.gstno,
         // Password: user.password,
       //   ConfirmPassword: user.confirmpassword,
         // Role: "Admin",
          
          //Operation: "ADD",
          //Social_media_type: "APPLICATION",
         // ApplicationType: applicationType,
          //Row_created_by: `${user.name} `,
         // Row_altered_by: `${user.name} `,
        });
        if (response?.status === "SUCCESS") {
          /**This is for save company details */
          setErrorMessage(undefined);
          resetForm({ user: ''});
          setResHeader("SUCCESS");
       
          setResMessage("Thank you for applying for this Job Position. Our team will  reachout to you soon. Thank you!");
          toggle();
        } else {
          setErrorMessage(response?.message);
          setResHeader("ERROR");
          setResMessage(response?.message);
          toggle();
    
        }
      } catch (error) {
        setErrorMessage(error);
        setResHeader("ERROR");
        setResMessage(error);
        toggle();
  
      } finally {
        setIsLoading(false);
      }
    };
   
    
    const initialValues =
    {
        name: "",
        email: "",
        phone: "",
        address: "",
        linkedin: "",
        currentsalary: "",
        experience: "",
        expectedsalary: "",
        note: "",
        hiddenField: "",
     };
     
     const phoneRegExp= /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
     const [errorMessage, setErrorMessage] = useState(undefined);
     const [isLoading, setIsLoading] = useState(false);

     const formik = useFormik({
           
        initialValues: {
           name: '',
           email: '',
           phone: '',
           message: '',
           address: '',
            linkedin: '',
            currentsalary: '',
            experience: '',
           expectedsalary: '',
            note: '',
            hiddenField: ''
      //
         },
     
         validationSchema: Yup.object({
           name: Yup.string()
             .min(2, 'Must be 2 characters or More')
             .max(25)
             .required('Please Enter Name'),
             email: Yup.string()
             .email('Invalid email address')
             .required('Please Enter Email'),
             phone: Yup.string()
            //  .max(10, 'Must be 10 characters')
              .matches(phoneRegExp,"Phone number is not valid")
             .required('Please Enter Phone'),
             address: Yup.string()
             .min(3, 'Must be 3 characters or More')
             .required('Please Enter Address'),
             linkedin: Yup.string()
             .min(3, 'Must be 3 characters or More')
             .required('Please Enter LinkedIn Profile'),
             currentsalary: Yup.string()
             //.min(5, 'Must be 5 characters or More')
             .required('Please Enter current salary'),
             //experience: Yup.string()
             // .required('Please Select experience'),
             
         }),
         onSubmit: (values,{resetForm})=> {
            debugger;
           {/* alert("name - " +  JSON.stringify(values.name, null, 2));
            alert("email - " +  JSON.stringify(values.email, null, 2));
            alert("phone - " +  JSON.stringify(values.phone, null, 2));
            alert("address - " +  JSON.stringify(values.address, null, 2));   
            alert("linkedin - " +  JSON.stringify(values.linkedin, null, 2));   
            alert("currentsalary - " +  JSON.stringify(values.currentsalary, null, 2));   */}
           // alert("experience - " +  JSON.stringify(selectedFruit, null, 2));    
            let selectedYear = $(selectRef.current).val();
            alert(` You have selected ${selectedYear}`);

            values.experience = selectedYear;
            UserInquiry(values,{resetForm});
            setResHeader("SUCCESS");
       
            setResMessage("Your Inquiry has been sent successfully. Our team will  reachout to you soon. Thankyou!");
          //  toggle();
           // alert("email - " +  JSON.stringify(values.email, null, 2));
           // alert("phone - " + JSON.stringify(values.phone, null, 2));
           // alert(JSON.stringify(values.message, null, 2));
           //UserInquiry(values,{resetForm});
           
         },
       }); 


       useEffect(() => {
      
           //   debugger;
           
    
  
     }, []);
    
    
    const mystyle =  {
      color: "#270",
      /*backgroundColor: "#DFF2BF"*/
    };
   
    return(
        <>
         <div id="show" className="career-details-hide-wrapper" style={{display:'block'}}>
                     <div className="career-details-apply-info-box pb-10">
                        <div className="career-details-profile-box pb-20">
                           <h4 className="career-details-title-xs">Profile</h4>
                           <p>Basic information about you</p>
                         </div>{/*/onSubmit={formik.handleSubmit} */}
                        <div className="postbox__comment-form">
                           <form action="#" 
                            onSubmit={(e) => {
                             e.preventDefault();
                             formik.handleSubmit();
                             return false;
                           }}
                            className="box">
                              <div className="row gx-20">
                                 <div className="col-12 mb-30">
                                    <div className="postbox__comment-input mb-5">
                                       <input 
                                       type="text" 
                                       className="inputText" 
                                       autoComplete="off" 
                                        name="name" 
                                        id="name"
                                       // placeholder="Your Full  Name"
                                        value={formik.values.name} 
                                        onChange={formik.handleChange} 
                                        onBlur={formik.handleBlur} 
                                        required
                                                 
                                       />
                                       <span className="floating-label">Full  Name</span>
                                       
                                       
                                    </div>
                                    {formik.touched.name && formik.errors.name ? (
                                             <div className="inputError pl-20">{formik.errors.name}</div>
                                          ) : null}
                                      
                                 </div>
                                 <div className="col-12 mb-30">
                                    <div className="postbox__comment-input mb-5">
                                       <input 
                                        type="text" 
                                        autoComplete="off" 
                                        name="email" 
                                        id="email"
                                     //   placeholder="Your Email"
                                        value={formik.values.email} 
                                        onChange={formik.handleChange} 
                                        onBlur={formik.handleBlur} 
                                        className="inputText"
                                        required
                                       />
                                       <span className="floating-label">Your Email</span>
                                          
                                       
                                    </div>
                                    {formik.touched.email && formik.errors.email ? (
                                           <div className="inputError pl-20">{formik.errors.email}</div>
                                                ) : null}
                                       
                                 </div>
                                 <div className="col-12 mb-30">
                                    <div className="postbox__comment-input mb-5">
                                       <input 
                                       type="text"
                                       autoComplete="off"
                                       name="phone"
                                       id="pone"
                                     //  placeholder="Phone Number" 
                                       value={formik.values.phone}
                                       onChange={formik.handleChange}
                                       onBlur={formik.handleBlur} 
                                       className="inputText"
                                       required/>
                                       <span className="floating-label">Phone</span>
                                        
                                       
                                    </div>
                                    {formik.touched.phone && formik.errors.phone ? (
                                           <div className="inputError pl-20">{formik.errors.phone}</div>
                                                                     ) : null}
                                        
                                 </div>
                                 <div className="col-12 mb-35">
                                    <div className="postbox__comment-input mb-5">
                                       <input type="text" 
                                                className="inputText" 
                                                autoComplete="off"
                                                name="address" 
                                                id="address"
                                              //  placeholder="Address" 
                                                value={formik.values.address}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                required/>
                                                <span className="floating-label">Address(City,State,Country)</span>
                                        
                                    </div>
                                    {formik.touched.address && formik.errors.address ? (
                                           <div className="inputError pl-20">{formik.errors.address}</div>
                                                                     ) : null}
                                    
                                 </div>
                              </div>
                              <div className="career-details-apply-info-box">
                         <div className="career-details-profile-box pb-20">
                           <h4 className="career-details-title-xs">Application Details</h4>
                           <p>Summarize your relevant experience</p>
                        </div>
                              <div className="row gx-20">
                                 <div className="col-12 mb-30">
                                    <div className="postbox__comment-input mb-5">
                                       <input type="text" 
                                        className="inputText"
                                        autoComplete="off"
                                        name="linkedin" 
                                        id="linkedin"
                                    //    placeholder="LinkedIn Profile" 
                                        value={formik.values.linkedin}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        required
                                        />
                                        <span className="floating-label">LinkedIn Profile</span>
                                          
                                       
                                    </div>
                                    {formik.touched.linkedin && formik.errors.linkedin ? (
                                           <div className="inputError pl-20">{formik.errors.linkedin}</div>
                                                                     ) : null}
                                        
                                 </div>
                                 <div className="col-12 mb-30">
                                    <div className="postbox__select mb-5 ">
                                       {/* <select ref={selectRef} className="wide"  */}
                                       <select ref={selectRef} className="wide" 
                                          //className="nice-select open" 
                                          style={{display: 'block'}}
                                           id="experience"
                                           name="experience"
                                           //value={formik.values.experience}
                                           //value={selectedFruit}

                                             onChange={e => setSelectedYearsOfExperience(e.target.value)}                                 
                                        //   onChange={formik.handleChange}
                                        //    onChange={(value) => formik.setFieldValue('experience', value)}
                                    
                                          required
                                       >
                                           
                                              <option value=''  >Years of experience</option>
                                               <option value='1' >01 Year</option>
                                               <option value='2' >02 Year</option>
                                               <option value='3' >03 Year</option>
                                               <option value='4' >04 Year</option>
                                               <option value='5' >05 Year</option>
                                         
                                          </select>
                                         
                                          {/* <option value='2' className="option">02 Year</option>
                                          <option value="3" className="option">03 Year</option>
                                          <option value="4" className="option">04 Year</option>
                                          <option value="5" className="option">05 Year</option></select> */}
                                          {/* <div class="nice-select open"
                                           tabindex="0">
                                             <span class="current">
                                                02 Year</span>
                                                <ul class="list">
                                                   <li data-value="Years of experience" class="option focus">Years of experience</li>
                                                   <li data-value="01 Year" class="option">01 Year</li>
                                                   <li data-value="02 Year" class="option selected">02 Year</li>
                                                   <li data-value="03 Year" class="option">03 Year</li>
                                                   <li data-value="04 Year" class="option">04 Year</li>
                                                   <li data-value="05 Year" class="option">05 Year</li>
                                                   </ul></div> */}
                                             {/* id={"years"}
                                            type={"text"}
                                             value={formik.values.salary}
                                            value={values.years}
                                            onChange={option => setFieldValue("years", option)}
                                            options={options}
                                            onBlur={formik.handleBlur} */}

                                       
                                    </div>
                                 </div>
                                 <div className="col-12 mb-30">
                                    <div className="postbox__comment-input mb-5">
                                       {/* <input 
                                       type="text" 
                                      // pattern="[0-9]*" 
                                      // inputmode="numeric" 
                                       className="inputText"
                                       autoComplete="off"
                                       name="currentsalary" 
                                       id="currentsalary"
                                      // placeholder="Personal website" 
                                       value={formik.values.currentsalary}
                                       onChange={formik.handleChange}
                                       onBlur={formik.handleBlur}
                                      // required

                                       /> 
                                  <CurrencyInput
                                     id="currentsalary"
                                     name="currentsalary"
                                     allowDecimals={false}
                                     http://localhost:3004/CareerDetails?jobId=1
                                    // placeholder="₹ 4,50,000"
                                    // defaultValue={450000}
                                     className="inputText"
                                     intlConfig={options[0]}
                                     prefix="₹ "
                                     maxLength="8"                                
                                    //  step={10}
                                     value={formik.values.currentsalary}

                                     onValueChange={(value) => formik.setFieldValue('currentsalary', value)}
                                  />*/}
                                  <CurrencyInput
                                     id="currentsalary"
                                     name="currentsalary"
                                     allowDecimals={false}
                                    // placeholder="₹ 4,50,000"
                                    // defaultValue={450000}
                                     className="inputText"
                                     intlConfig={options[0]}
                                     prefix="₹ "
                                     maxLength="8"   
                                    // onBlur={formik.handleBlur}  
                                     value={formik.values.currentsalary}
                                     onValueChange={(value) => formik.setFieldValue('currentsalary', value)}
                                     required
                                  />

                                    <span className="floating-label">Current Salary in Lacs</span>
                                          
                                       
                                    </div>
                                    {formik.touched.currentsalary && formik.errors.currentsalary ? (
                                           <div className="inputError pl-20">{formik.errors.currentsalary}</div>
                                                                     ) : null}
                                      
                                 </div>
                               
                                 <div className="col-12 mb-35">
                                    <div className="postbox__comment-input mb-5">
                                     {/* <input 
                                       type="text" 
                                        pattern="[0-9]*" 
                                        inputmode="numeric" 
                                        className="inputText"
                                       autoComplete="off"
                                       name="expectedsalary" 
                                       id="expectedsalary"
                                       placeholder="Expected salary" 
                                       value={formik.values.expectedsalary}
                                       onChange={formik.handleChange}
                                       onBlur={formik.handleBlur}
                                       required
                                       />  */}
                                        <CurrencyInput
                                     id="expectedsalary"
                                     name="expectedsalary"
                                     allowDecimals={false}
                                     //placeholder="₹ 4,50,000"
                                    // defaultValue={450000}
                                     className="inputText"
                                     prefix="₹ " 
                                     maxLength="8"
                                     intlConfig={options[0]}
                                    //  step={10}
                                    required
                                     value={formik.values.expectedsalary}

                                     onValueChange={(value) => formik.setFieldValue('expectedsalary', value)}
                                  />
                                    <span className="floating-label">Expected salary in Lacs</span>
                                       
                                       
                                    </div>
                                    {formik.touched.expectedsalary && formik.errors.expectedsalary ? (
                                           <div className="inputError pl-20">{formik.errors.expectedsalary}</div>
                                                                     ) : null}
                                       
                                 </div> 
                              </div>
                              
                        {/* <div className="col-xxl-12">
                           <div className="postbox__authorization-title-box">
                              <h5 className="career-details-title-xs pb-15">US work authorization<span>*</span></h5>
                           </div>
                           <div className="postbox__authorization p-relative">
                              <div className="switcher mb-15">
                                 <label htmlFor="toggle-0">
                                    <small className="switcher-icon">
                                       <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M1 5.5H13.0305" stroke="#5F6168" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                          <path d="M10.0234 1.5L14.0336 5.5L10.0234 9.5" stroke="#5F6168" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                       </svg>
                                       Are you legally authorized to work in the <br/> United States?
                                    </small>
                                    <input type="checkbox" id="toggle-0"/>
                                    <span><small></small></span>
                                 </label>
                              </div>
                              <div className="switcher">
                                 <label htmlFor="toggle-1">
                                    <small className="switcher-icon">
                                       <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M1 5.5H13.0305" stroke="#5F6168" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                          <path d="M10.0234 1.5L14.0336 5.5L10.0234 9.5" stroke="#5F6168" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                       </svg>
                                       Do you or will you require sponsorship for a US <br/> employment visa (e.g. H-1B)?
                                    </small>
                                    <input type="checkbox" id="toggle-1"/>
                                    <span><small></small></span>
                                 </label>
                              </div>

                           </div>
                        </div>*/}
                         <div className="col-xxl-12"> 
                           <div className="postbox__comment-input mb-30">
                              <textarea
                               className="textareaText" 
                                autoComplete="off"
                                name="note" 
                                id="note"
                                placeholder="Personal note" 
                                value={formik.values.note}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                >
                                
                               </textarea>
                               {formik.touched.note && formik.errors.note ? (
                                           <div className="inputError pl-20">{formik.errors.note}</div>
                                                                     ) : null}
                                        
                              <span className="floating-label-2">Personal note</span>
                           </div>
                        </div> 
                         <div className="col-xxl-12">
                           <div className="postbox__resume-title-box">
                              <h5 className="career-details-title-xs pb-15">Upload a recent resume or CV</h5>
                           </div>
                           <div className="postbox__resume mb-30">
                              <input id="fileName" name="fileName" type="file"  onChange={onFileChangeHandller} required/>
                              {/* <label htmlFor="cv">
                                 <span>
                                    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                       <path d="M11.9133 10.4519L9.00453 7.54309L6.0957 10.4519" stroke="#202124" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                       <path d="M9.00391 7.54309V14.0879" stroke="#202124" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                       <path d="M15.1044 12.1899C15.8137 11.8032 16.374 11.1914 16.6969 10.4509C17.0198 9.7104 17.0869 8.88347 16.8877 8.1006C16.6884 7.31774 16.2341 6.62352 15.5965 6.12752C14.9588 5.63152 14.1742 5.36198 13.3664 5.36145H12.4501C12.23 4.51006 11.8197 3.71966 11.2502 3.04965C10.6806 2.37965 9.96657 1.84748 9.16174 1.49315C8.35691 1.13883 7.48222 0.971567 6.60344 1.00395C5.72467 1.03632 4.86466 1.2675 4.08808 1.68009C3.31151 2.09268 2.63857 2.67595 2.11986 3.38605C1.60115 4.09615 1.25017 4.9146 1.09331 5.77988C0.936443 6.64515 0.977774 7.53472 1.21419 8.38172C1.45061 9.22872 1.87597 10.0111 2.45829 10.67" stroke="#202124" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                       <path d="M11.9133 10.4519L9.00453 7.54309L6.0957 10.4519" stroke="#202124" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    Drag to upload your resume, or browse
                                 </span>
                              </label> */}
                           </div>
                        </div> 
                        <div> <input type="hidden" id="hiddenField" 
                        name="hiddenField" value={formik.values.hiddenField=props.setJobPosition}/>
  
                           </div>
                        <div className="col-xxl-12">
                           <div className="postbox__btn-box mb-50">
                              <button className="submit-btn w-100" type="submit" >
							  {isLoading ? (
                                        <LoadingOutlined
                                        style={{
                                          fontSize: 24,
                                          marginRight: "5px",
                                        }}
                                        spin
                                      />
                                    ) : (
                                        <></>
                                    )} 
							  Apply For This Job</button>
                           </div>
                                       
                        </div>

                       
                     </div>
                           </form>
                        </div>
                     </div>
                     
                  </div>
                  <ResponseStatus
         Responsemodal={modal}
         Responsetoggle ={toggle}

        ResponseHeader = {resHeader}
        ResponseMessage = {resMessage}
        closeBothModals = {toggle}
        />
        </>
    )
}