
import About from "./Components/Pages/About/About";
import Contact from "./Components/Pages/Contact/Contact";
import Home from "./Components/Pages/Home/Home";
import Projects from "./Components/Pages/Projects/Projects";
import Services from "./Components/Pages/Services/Services";
import ServiceDetails from "./Components/Pages/Services/ServiceDetails";
import Career from "./Components/Pages/Career/Career";
import { BrowserRouter as Router , Route , Routes } from "react-router-dom";
import CareerDetails from "./Components/Pages/Career/CareerDetails";
//import cors from cors;
function App() {
  //const cors = require('cors');
  //app.use(cors());
  

  return (
    <>
     <Router>
       
       <Routes>
         
        <Route exact path="/" Component={Home}/>
        <Route path="/About" Component={About}/>
         <Route path="/Projects" Component={Projects}/>
         <Route path="/Services" Component={Services}/> 
         <Route path="/Career" Component={Career}/> 
        <Route path="/Contact" Component={Contact}/>
        <Route path="/ServiceDetails" Component={ServiceDetails}/>
        <Route path="/CareerDetails" Component={CareerDetails}/>
        </Routes>
      
      
      </Router> 

       
      

      
      
       
      
    </>
  );
}

export default App;
