import React,{useEffect , useState} from "react";  
import Footer1 from "../../Common/Footer1";
import Header1 from "../../Common/Header1";
import Review from "./Review";
import {Route,useNavigate} from 'react-router-dom';
import JsonData from "../../../JSONData/ServiceData.json";
 
export default function Home() {
   const navigate = useNavigate();
   const [serviceList, setServiceList] = useState([]);
   
  
  useEffect(() => {
      setServiceList(JsonData);
   }, []);

    return (
       <>
      <Header1/>
          
   <div id="smooth-wrapper">
      <div id="smooth-content">

          <main className="fix"> 
            
            {/* <!-- tp-slider-area-start --> */}
            <div className="tp-hero__area tp-hero__pt-5">
               <div className="tp-hero__bg p-relative">
                  <div className="tp-hero-bg tp-hero-bg-single">
                     <img src="assets/img/hero/hero-frame.png" alt=""/>
                  </div>
                  <div className="tp-hero-shape">
                     <img className="tp-hero-shape-1" src="assets/img/hero/hero-line-shape.png" alt=""/>
                     <img className="tp-hero-shape-2" src="assets/img/hero/hero-line-shape-2.png" alt=""/>
                  </div>
                  <div className="container">
                     <div className="row justify-content-center">
                        <div className="col-xl-10">
                           <div className="tp-hero__content-box text-center z-index-3">
                              <div className="tp-hero__title-box p-relative">
                                 <h2  className="tp-hero__hero-title tp-title-anim" >
                                 Crafting Ideas into <br/>Digital Reality
                                 </h2>
                                 <div className="tp-hero__title-shape d-none d-sm-block">
                                    <svg width="491" height="24" viewBox="0 0 491 26" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                       <path className="line1" fillRule="evenodd" clipRule="evenodd"
                                          d="M208.549 3.29849C185.59 4.08616 162.901 5.05468 140.776 6.11351C109.455 7.61138 78.1462 9.16091 47.0261 11.6143C37.2302 12.3891 27.2823 12.8797 17.5476 13.8482C11.4368 14.4551 3.334 15.3201 2.27061 15.5009C1.71441 15.6042 1.47176 15.7593 1.39336 15.8109C0.842061 16.1725 0.942652 16.521 1.25383 16.7921C1.37879 16.9084 1.69478 17.1925 2.57195 17.2313C61.2348 19.8784 121.221 14.7133 179.955 14.171C281.81 13.2413 386.752 16.9343 487.799 24.9918C488.681 25.0564 489.539 24.7334 489.661 24.2557C489.808 23.7908 489.171 23.3389 488.289 23.2743C387.071 15.2039 281.957 11.498 179.906 12.4406C125.168 12.9442 69.3499 17.4766 14.5363 15.9271C15.7957 15.798 17.0307 15.6688 18.1578 15.5526C27.8533 14.5841 37.7594 14.1064 47.5162 13.3316C78.5701 10.8782 109.815 9.32872 141.095 7.84376C179.906 5.98434 220.408 4.38316 261.228 3.63423C275.831 3.75044 290.386 3.86671 304.94 4.00875C336.425 4.31865 368.057 5.22254 399.469 6.43633C408.927 6.8108 418.385 7.19814 427.842 7.53387C430.979 7.65009 439.064 7.98576 440.191 7.95993C441.588 7.93411 441.857 7.31436 441.882 7.21106C441.955 6.97863 441.906 6.65579 441.22 6.38463C441.147 6.34589 440.706 6.21668 439.726 6.13921C382.611 1.56813 321.626 0.806405 261.277 1.90398C197.621 1.42621 133.719 1.21951 70.2247 1C69.3157 1 68.5733 1.38744 68.5659 1.8652C68.561 2.34297 69.2935 2.73042 70.2026 2.74333C116.178 2.89828 162.387 3.05315 208.549 3.29849Z"
                                          fill="none" stroke="none" />
                                    </svg>                             
                                 </div>
                              </div>
                              <div className="tp-hero__input p-relative wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".5s">
                                                             </div>
                             </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="tp-hero__bottom z-index-5">
                  <div className="container">
                     <div className="row justify-content-center">
                        <div className="col-xl-10">
                           <div className="tp-hero__thumb-wrapper-main p-relative">
                              <div className="tp-hero__shape-img-1 d-none d-xl-block">
                                 <img src="assets/img/hero/hero-shape-1.png" alt=""/>
                              </div>
                              <div className="tp-hero__shape-img-2 d-none d-xl-block">
                                 <img src="assets/img/hero/hero-shape-2.png" alt=""/>
                              </div>
                              <div>
                                 <div className="tp-hero__thumb-wrapper d-none d-md-block p-relative">
                                    <div className="row">
                                       <div className="col-8">
                                          <div className="tp-hero__thumb-box">
                                             <div className="row">
                                                <div className="col-md-12">
                                                   <div className="tp-hero__thumb mb-20">
                                                      <img className="w-100" src="assets/img/hero/hero-sm-1.jpg" alt=""/>
                                                   </div>
                                                </div>
                                             </div>
                                             <div className="row">
                                                <div className="col-md-4">
                                                   <div className="tp-hero__thumb tp-hero__sm-img">
                                                      <img className="w-100" src="assets/img/hero/hero-sm-3.png" alt=""/>
                                                   </div>
                                                </div>
                                                <div className="col-md-8">
                                                   <div className="tp-hero__thumb">
                                                      <img className="w-100" src="assets/img/hero/hero-sm-4.jpg" alt=""/>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-4">
                                          <div className="tp-hero__thumb-box">
                                             <div className="tp-hero__thumb">
                                                <img className="w-100" src="assets/img/hero/hero-sm-2.jpg" alt=""/>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {/* <!-- tp-slider-area-end --> */}

            {/* <!-- tp-feature-area-start --> */}
            <div className="tp-feature__area tp-feature__pt-pb pt-165 pb-170 p-relative">
               <div className="tp-feature__bottom-shape">
                  <img src="assets/img/feature/fea-bg-shape-1.png" alt=""/>
               </div>
               <div className="container">
                  <div className="row justify-content-center">
                     <div className="col-xl-6 wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".2s">
                        <div className="tp-feature__section-box text-center mb-55 tp-title-anim">
                           <h2 className="tp-section-title">Our Exciting Features</h2>
                           <p>We Code Your Vision into Reality</p>
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-xl-4 col-lg-4 col-md-6 mb-30 wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".4s">
                        <div id="divtest" className="tp-feature__item">
                           <div className="tp-feature__icon">
                              <img src="assets/img/feature/fea-icon-1.png" alt=""/>
                           </div>
                           <h3 className="tp-feature__title-sm">Cutting-edge technologies</h3>
                           {/* <div className="tp-feature__link">
                              <a href="/#">
                                 <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.00098 7H13.001" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M7.00098 1L13.001 7L7.00098 13" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                 </svg>
                              </a>
                           </div> */}
                        </div>
                     </div>
                     <div className="col-xl-4 col-lg-4 col-md-6 mb-30 wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".6s">
                        <div className="tp-feature__item">
                           <div className="tp-feature__icon">
                              <img src="assets/img/feature/fea-icon-2.png" alt=""/>
                           </div>
                           <h3 className="tp-feature__title-sm">Scalability and Cross-Platform Compatibility
</h3>
                           {/* <div className="tp-feature__link">
                              <a href="/#">
                                 <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.00098 7H13.001" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M7.00098 1L13.001 7L7.00098 13" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                 </svg>
                              </a>
                           </div> */}
                        </div>
                     </div>
                     <div className="col-xl-4 col-lg-4 col-md-6 mb-30 wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".8s">
                        <div className="tp-feature__item">
                           <div className="tp-feature__icon">
                              <img src="assets/img/feature/fea-icon-3.png" alt=""/>
                           </div>
                           <h3 className="tp-feature__title-sm">Continuous Support and Maintenance <br/> </h3>
                           {/* <div className="tp-feature__link">
                              <a href="/#">
                                 <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.00098 7H13.001" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M7.00098 1L13.001 7L7.00098 13" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                 </svg>
                              </a>
                           </div> */}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {/* <!-- tp-feature-area-end --> */}


            {/* <!-- tp-about-area-start --> */}
            <div className="tp-about__area tp-about__pt-pb pt-100 pb-160">
               <div className="container">
                  <div className="row align-items-center">
                     <div className="col-xl-6 col-lg-6 wow tpfadeLeft" data-wow-duration=".9s" data-wow-delay=".2s">
                        <div className="tp-about__img-wrapper text-center text-lg-end p-relative">
                           <div className="tp-about__bg-shape">
                              <img src="assets/img/about/about-bg-shape.png" alt=""/>
                           </div>
                           <div className="tp-about__main-img z-index">
                              <img src="assets/img/about/about-2.jpg" alt=""/>
                           </div>
                           <div className="tp-about__sub-img-1 d-none d-sm-block z-index-3">
                              <img src="assets/img/about/about-1.jpg" alt=""/>
                           </div>
                           <div className="tp-about__sub-img-2 d-none d-sm-block">
                              <img src="assets/img/about/about-3.jpg" alt=""/>
                           </div>
                           <div className="tp-about__sub-img-3 d-none d-sm-block z-index-3">
                              <img src="assets/img/about/about-5.png" alt=""/>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-6 col-lg-6 wow tpfadeRight" data-wow-duration=".9s" data-wow-delay=".6s">
                        <div className="tp-about__right">
                           <div className="tp-about__section-box">
                              <h4 className="tp-section-subtitle">OVER 50+ CLIENT</h4>
                              <h3 className="tp-section-title mb-15">Digitalizing the World..!!
                              </h3>
                                     <p>We have been at the forefront of technological innovation,
                                        delivering cutting-edge solutions to businesses across industries.</p>
                           </div>
                           <div className="tp-about__list">
                              <ul>
                                 <li><i className="fal fa-check"></i>Various analysis options.</li>
                                 <li><i className="fal fa-check"></i>Page Load (time, size, number of requests).</li>
                                 <li><i className="fal fa-check"></i>Big data analysis.</li>
                              </ul>
                           </div>
                           <div className="tp-about__btn">
                              <a className="tp-btn tp-btn-hover alt-color-black" href="/About">
                                 <span>About Us</span>
                                 <b></b>
                              </a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {/* <!-- tp-about-area-end --> */}

            {/* <!-- tp-services-area-start --> */}
            <div className="tp-service__area p-relative fix">
               {/* <div className="tp-service__grey-shape grey-bg"></div> */}
               <div className="container">
                  <div className="row justify-content-center">
                     <div className="col-lg-8">
                        <div className="tp-service__section-box mb-50 text-center tp-title-anim">
                           <h2 className="tp-section-title">Explore Our Services</h2>
                           <p>More than 50 companies trust and choose TheDigitaLogic</p>
                        </div>
                     </div>
                  </div>
                  <div className="row">
                  {/* serviceList */}
                  {serviceList.map(s => (
                  // <li>{s}</li>
                  <div className="col-xl-4 col-lg-4 col-md-6 wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".9s">
                        <div className="tp-service__item mb-30">
                           <div className="tp-service__icon">
                              <img src={s.Service_icon} alt=""/>
                           </div>
                           <div className="tp-service__content">
                              <h3 className="tp-service__title-sm tp-yellow-color"><a href={"/ServiceDetails?serviceId="+ s.id} 
                              >{s.Service_name} 
</a></h3>
                                     <p>{s.Service_description}</p>
                           </div>
                           <div className="tp-service__link">
                               <a href={"/ServiceDetails?serviceId="+ s.id}> {/*onClick={this.handleQueryString}
                              > */}
                                 <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.00098 7H13.001" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M7.00098 1L13.001 7L7.00098 13" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                 </svg>
                              </a>
                             
                           </div>
                        </div>
                     </div>
                  
                  
                  
                  
                  
                  
                  
                  
                  
                  
                  
                  
                  ))}
                  {/* <div className="col-xl-4 col-lg-4 col-md-6 wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".9s">
                        <div className="tp-service__item mb-30">
                           <div className="tp-service__icon">
                              <img src="assets/img/service/sv-icon-5.png" alt=""/>
                           </div>
                           <div className="tp-service__content">
                              <h3 className="tp-service__title-sm tp-yellow-color"><a href="" id={1} onClick={shoot}>Website designing 
</a></h3>
                                     <p>Website designing is a crucial aspect of creating a successful online presence <br />
                                     for individuals, businesses, or organizations.</p>
                           </div>
                           <div className="tp-service__link">
                              <a href="/#">
                                 <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.00098 7H13.001" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M7.00098 1L13.001 7L7.00098 13" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                 </svg>
                              </a>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-4 col-lg-4 col-md-6 wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".8s">
                        <div className="tp-service__item mb-30">
                           <div className="tp-service__icon">
                              <img src="assets/img/service/sv-icon-4.png" alt=""/>
                           </div>
                           <div className="tp-service__content">
                              <h3 className="tp-service__title-sm tp-green-color"><a href="/#">UI/UX Design
</a></h3>
                              <p>User Interface/User Experience design, is a critical aspect of creating digital products that are not  <br/>
                              only visually appealing but also intuitive and user-friendly. </p>
                           </div>
                           <div className="tp-service__link">
                              <a href="/#">
                                 <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.00098 7H13.001" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M7.00098 1L13.001 7L7.00098 13" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                 </svg>
                              </a>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-4 col-lg-4 col-md-6 wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".8s">
                        <div className="tp-service__item mb-30">
                           <div className="tp-service__icon">
                              <img src="assets/img/service/sv-icon-4.png" alt=""/>
                           </div>
                           <div className="tp-service__content">
                              <h3 className="tp-service__title-sm tp-green-color"><a href="/#">Web App Development

                                 </a></h3>
                                     <p> Involves designing and building interactive applications
                                        accessible through web browsers, incorporating front-end
                                        interfaces,
                                        <br />
                                        back-end logic, and database integration for seamless user experiences. </p>
                           </div>
                           <div className="tp-service__link">
                              <a href="/#">
                                 <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.00098 7H13.001" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M7.00098 1L13.001 7L7.00098 13" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                 </svg>
                              </a>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-4 col-lg-4 col-md-6 wow tpfadeUp" data-wow-duration=".9s" 
                     data-wow-delay=".7s">
                        <div className="tp-service__item mb-30">
                           <div className="tp-service__icon">
                              <img src="assets/img/service/sv-icon-3.png" alt=""/>
                           </div>
                           <div className="tp-service__content">
                              <h3 className="tp-service__title-sm tp-purple-color"><a href="/#">Mobile App Development</a></h3>
                              <p>Our proficiency extends to developing responsive and intuitive  <br/>
                              web and mobile applications</p>
                           </div>
                           <div className="tp-service__link">
                              <a href="/#">
                                 <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.00098 7H13.001" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M7.00098 1L13.001 7L7.00098 13" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                 </svg>
                              </a>
                           </div>
                        </div>
                     </div>
                     
                     <div className="col-xl-4 col-lg-4 col-md-6 wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".4s">
                        <div className="tp-service__item mb-30">
                           <div className="tp-service__icon">
                              <img src="assets/img/service/sv-icon-1.png" alt=""/>
                           </div>
                           <div className="tp-service__content">
                              <h3 className="tp-service__title-sm tp-orange-color"><a href="/#">
                                 Custom Software Development</a></h3>
                              <p>We excel in crafting bespoke software solutions tailored <br/>
                              to meet the unique needs and challenges of 
                              our clients. </p>
                           </div>
                           <div className="tp-service__link">
                              <a href="/#">
                                 <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.00098 7H13.001" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M7.00098 1L13.001 7L7.00098 13" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                 </svg>
                              </a>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-4 col-lg-4 col-md-6 wow tpfadeUp" data-wow-duration=".9s"
                      data-wow-delay=".6s">
                        <div className="tp-service__item mb-30">
                           <div className="tp-service__icon">
                              <img src="assets/img/service/sv-icon-2.png" alt=""/>
                           </div>
                           <div className="tp-service__content">
                              <h3 className="tp-service__title-sm tp-theme-color">
                                 <a href="/#">Window App Development</a></h3>
                              <p>From concept to deployment, we bring ideas to life with  <br/>
                              precision and efficiency</p>
                           </div>
                           <div className="tp-service__link">
                              <a href="/#">
                                 <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.00098 7H13.001" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M7.00098 1L13.001 7L7.00098 13" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                 </svg>
                              </a>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-4 col-lg-4 col-md-6 wow tpfadeUp" data-wow-duration=".9s"
                      data-wow-delay=".6s">
                        <div className="tp-service__item mb-30">
                           <div className="tp-service__icon">
                              <img src="assets/img/service/sv-icon-2.png" alt=""/>
                           </div>
                           <div className="tp-service__content">
                              <h3 className="tp-service__title-sm tp-theme-color">
                                        <a href="/#">Digital Marketing
                                        </a></h3>
                              <p>use of online channels and platforms to promote products, services, 
                                 or brands, employing strategies such as social media marketing,  (SEO), 
                                 content marketing,  <br/>
                                 email marketing to reach and engage target audiences.</p>
                           </div>
                           <div className="tp-service__link">
                              <a href="/#">
                                 <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.00098 7H13.001" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M7.00098 1L13.001 7L7.00098 13" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                 </svg>
                              </a>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-4 col-lg-4 col-md-6 wow tpfadeUp" data-wow-duration=".9s"
                      data-wow-delay=".6s">
                        <div className="tp-service__item mb-30">
                           <div className="tp-service__icon">
                              <img src="assets/img/service/sv-icon-2.png" alt=""/>
                           </div>
                           <div className="tp-service__content">
                              <h3 className="tp-service__title-sm tp-theme-color">
                                        <a href="/#">E-commerce Solutions
                                        </a></h3>
                              <p>the comprehensive set of technologies, tools, and services that enable businesses to conduct online transactions, 
                                 manage digital storefronts,  <br/>
                                 and facilitate the buying and selling of goods and services over the internet.</p>
                           </div>
                           <div className="tp-service__link">
                              <a href="/#">
                                 <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.00098 7H13.001" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M7.00098 1L13.001 7L7.00098 13" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                 </svg>
                              </a>
                           </div>
                        </div>
                     </div>
                      */}

                     {/* <div className="col-xl-4 col-lg-4 col-md-6 wow tpfadeUp" data-wow-duration=".9s" data-wow-delay="1s">
                        <div className="tp-service__dashboard"
                        style={{backgroundImage: `url("assets/img/service/sv-bg.jpg")`}}
                       >
                       
                                  <div className="tp-service__top-content">
                                     <h3 className="tp-service__title-white">Software Testing and
                                        <br /> Quality Assurance</h3>
                                     <p>systematic process and set of activities implemented within a project or organization to ensure that the developed products or services meet 

                                        <br /> specified requirements and comply with established standards.</p>
                              <a className="tp-btn-orange tp-btn-hover alt-color-white" 
                              href="/#">
                                 <span>Work with Us</span>
                                 <b></b>
                              </a>
                           </div>
                           <div className="tp-service__dashdboard-sm-img">
                              <img className="wow tpfadeRight" data-wow-duration=".9s" data-wow-delay=".7s" 
                              src="assets/img/service/sv-dashbord.png" alt=""/>
                           </div>
                        </div>
                     </div> */}
                  </div>
               </div>
            </div>
            {/* <!-- tp-services-area-end --> */}

            {/* <!-- tp-project-area-start --> */}
            {/* <!-- tp-project-area-end --> */}

            {/* <!-- tp-testimonial-area-start --> */}
            <Review/>
            {/* <!-- tp-testimonial-area-end --> */}

            {/* <!-- tp-rank-area-start --> */}
            <div className="tp-rank__area pb-200">
               <div className="container">
                  <div className="row">
                     <div className="col-xl-5 col-lg-5 order-1 order-lg-1">
                        <div className="tp-rank__section-box pb-25 wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".3s">
                           <h4 className="tp-section-subtitle">Top Sellings</h4>
                           <h3 className="tp-section-title">Generate more revenue <br/> using our Top Selling  <br/> Projects
                           </h3>
                        </div>
                        {/* <div className="tp-rank__btn wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".5s">
                           <a className="tp-btn tp-btn-hover alt-color-black" href="/#">
                              <span>Explore More</span>
                              <b></b>
                           </a>
                        </div> */}
                     </div>
                     <div className="col-xl-7 col-lg-7 order-0 order-lg-2">
                        <div className="tp-rank__wrapper p-relative">
                           <div className="tp-rank__circle-shape-1 d-none d-sm-block wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".3s">
                              <img src="assets/img/rank/sky-circle.png" alt=""/>
                           </div>
                           <div className="tp-rank__circle-shape-2 d-none d-sm-block wow tpfadeLeft" data-wow-duration=".9s" data-wow-delay=".5s">
                              <img src="assets/img/rank/yellow-circle.png" alt=""/>
                           </div>
                           <div className="tp-rank__circle-shape-3 d-none d-sm-block wow tpfadeRight" data-wow-duration=".9s" data-wow-delay=".4s">
                              <img src="assets/img/rank/black-circle.png" alt=""/>
                           </div>
                           <div className="tp-rank__circle-shape-4 d-none d-sm-block wow tpfadeIn" data-wow-duration=".9s" data-wow-delay=".7s">
                              <img src="assets/img/rank/black-sm-circle.png" alt=""/>
                           </div>
                           <div className="tp-rank__circle-shape-5 d-none d-sm-block wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".9s">
                              <img src="assets/img/rank/black-sm-circle.png" alt=""/>
                           </div>
                           <div className="tp-rank__circle-shape-6 d-none d-sm-block wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".2s">
                              <img src="assets/img/rank/black-sm-circle.png" alt=""/>
                           </div>
                           <div className="tp-rank__circle-shape-7 d-none d-sm-block wow tpfadeIn" data-wow-duration=".9s" data-wow-delay="1s">
                              <img src="assets/img/rank/black-sm-circle.png" alt=""/>
                           </div>
                           <div className="tp-rank__bg-shape">
                              <img className="wow tpfadeRight" data-wow-duration=".9s" data-wow-delay="1.2s" 
                              src="assets/img/rank/rank-bg-shape.png" alt=""/>
                           </div>
                           <div className="tp-rank__rank-card wow tpfadeRight" data-wow-duration=".9s" data-wow-delay=".7s">
                              <div className="tp-rank__item active p-relative z-index">
                                 <div className="tp-rank__cup">
                                    <span><img src="assets/img/rank/rank-cup.png" alt=""/></span>
                                 </div>
                                 <div className="tp-rank__number d-flex align-items-center justify-content-center">
                                    <i>#</i>
                                    <span>1</span>
                                 </div>
                                 {/* <div className="tp-rank__company">
                                    <span><img src="" alt=""/></span>
                                 </div> */}
                                 <div className="tp-rank__company-info">
                                    <a href="/#">B2BTrav</a>
                                    <span></span>
                                 </div>
                              </div>
                              <div className="tp-rank__item p-relative">
                                 <div className="tp-rank__number d-flex align-items-center justify-content-center">
                                    <i>#</i>
                                    <span>2</span>
                                 </div>
                                 {/* <div className="tp-rank__company">
                                    <span><img src="" alt=""/></span>
                                 </div> */}
                                 <div className="tp-rank__company-info">
                                    <a href="/#">TravelNinjaz </a>
                                    <span></span>
                                 </div>
                              </div>
                              <div className="tp-rank__item p-relative z-index">
                                 <div className="tp-rank__number d-flex align-items-center justify-content-center">
                                    <i>#</i>
                                    <span>3</span>
                                 </div>
                                 {/* <div className="tp-rank__company">
                                    <span><img src="" alt=""/></span>
                                 </div> */}
                                 <div className="tp-rank__company-info">
                                    <a href="/#">Aaahvaan</a>
                                    <span></span>
                                 </div>
                              </div>
                              <div className="tp-rank__item p-relative">
                                 <div className="tp-rank__number d-flex align-items-center justify-content-center">
                                    <i>#</i>
                                    <span>4</span>
                                 </div>
                                 {/* <div className="tp-rank__company">
                                    <span><img src="" alt=""/></span>
                                 </div> */}
                                 <div className="tp-rank__company-info">
                                    <a href="/#">C R M</a>
                                    <span></span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {/* <!-- tp-rank-area-end --> */}
            </main>
                        <Footer1/>
            </div>
            </div>
            

      </>
    );
}
