import React, { useState,useEffect } from "react";
import {
   Button, Modal, ModalFooter,
   ModalHeader, ModalBody
} from "reactstrap"

const ResponseStatus = ({Responsemodal,Responsetoggle,  ResponseHeader, ResponseMessage , closeBothModals }) => {
   
   const suc="SUCCESS";
  
   const mystyle =  {
      color: "#270",
      /*backgroundColor: "#DFF2BF"*/
    };
    return (

      <>
   <Modal isOpen={Responsemodal} toggle={Responsetoggle} centered={true}>
                 
   <ModalHeader> 
   {/* {ResponseHeader}
   {suc}
   {ResponseHeader===suc ? "Y" : "N" } */}
   {ResponseHeader===suc ? (
       <div  style={mystyle}>
       <i class="fa fa-check pr-10"></i>
        {ResponseHeader} 
    </div>
      ) : (
         <div  style={{color: "#D8000C",}}>
         <i class="fa fa-times-circle pr-10"></i>
           {ResponseHeader} 
      </div>
      )}
      
  </ModalHeader>  
       <ModalBody>
          <div className="inner-project-content">
             <p>  
               {ResponseMessage}
               {/* Your Inquiry has been sent successfully. Our team will  reachout to you soon. Thankyou! */}
             </p>
          </div>
       </ModalBody> 
      <ModalFooter> 
         <div class="contact-form-right-warp">
         <div className="postbox__btn-box">
            <button className="submit-btn w-100" type="submit" onClick={closeBothModals}>Okay</button>
         </div>
      </div>

      </ModalFooter>  

</Modal> 
</>


);
  
}

export default ResponseStatus;
