import React, { useState } from "react"; 
// import OffCanvas from "./OffCanvas";
import $ from "jquery";
window.jQuery = window.$ = $;

export default function Header1() {
   const [menuStatus, setMenuStatus] = useState("");
   

   const handleOpenMenu = () => {
      setMenuStatus("opened");    
   };

   const handleCloseMenu = () => {
      setMenuStatus("");
   };



   // $(".tp-menu-bar").on("click", function () {
	// 	$(".tpoffcanvas").addClass("opened");
	// 	$(".body-overlay").addClass("apply");
	// });
	// $(".close-btn").on("click", function () {     
	// 	$(".tpoffcanvas").removeClass("opened");
	// 	$(".body-overlay").removeClass("apply");
	// });
	// $(".body-overlay").on("click", function () {
	// 	$(".tpoffcanvas").removeClass("opened");
	// 	$(".body-overlay").removeClass("apply");
	// });


    return (
      <>
      {/* <!-- tp-offcanvus-area-start --> */}
      <div className="tpoffcanvas-area">
         <div className={menuStatus == "opened" ? "tpoffcanvas opened" : "tpoffcanvas"}>
            <div className="tpoffcanvas__close-btn">
               <button className="close-btn" onClick={handleCloseMenu}><i className="fal fa-times"></i></button>
            </div>
             <div className="text-center"> {/*className="tpoffcanvas__logo text-center"> */}
               {/* <a href="index.html">
                  <img src="assets/img/logo/logo-white.png" alt=""/>
               </a>*/}
               <a className="logo white-logo" href="/" > 
                        <img src="assets/img/logo/logo.png" alt=""/>
                         <b style={{color:"#fff"}}> THEDigitaLogic </b>
                     </a>
            </div>
            <div className="mobile-menu mean-container">
               
            <div className="mean-bar"><a href="#nav" className="meanmenu-reveal" style={{right: '0px',left: 'auto',display: 'inline'}}><span><span><span></span></span></span></a><nav className="mean-nav">
                           <ul style={{display:'none'}}>
                              <li className="">
                                 <a href="/">Home</a>
                                
                              <a className="mean-expand" href="#" style={{fontsize: '18px'}}>
                                 <i className="fal fa-plus"></i></a></li>
                              <li>
                                 <a href="/About">About</a>
                                 
                              <a className="mean-expand" href="#" style={{fontsize:'18px'}}><i className="fal fa-plus"></i>
                              </a></li>
                              <li>
                                 <a href="/Projects">Projects</a>
                                 
                              <a className="mean-expand" href="#" style={{fontsize: '18px'}}><i className="fal fa-plus"></i></a></li>
                              <li>
                                 <a href="/Services">Services</a>
                                 
                              <a className="mean-expand" href="#" style={{fontsize: '18px'}}><i className="fal fa-plus"></i></a></li>
                              <li>
                                 <a href="/Career">Career</a>
                                 
                              <a className="mean-expand" href="#" style={{fontsize: '18px'}}><i className="fal fa-plus"></i></a></li>
                              
                              <li className="mean-last"><a href="/contact">Contact</a></li>
                           </ul>
                        </nav></div>
            </div>
            <div className="tpoffcanvas__instagram text-center">
               <div className="tpoffcanvas__instagram-title">
                  <h4>instagram</h4>
               </div>
               <a href="#"><img src="assets/img/project/project-inner-4.jpg" alt=""/></a>
               <a href="#"><img src="assets/img/project/project-inner-5.jpg" alt=""/></a>
               <a href="#"><img src="assets/img/project/project-inner-6.jpg" alt=""/></a>
               <a href="#"><img src="assets/img/project/project-inner-7.jpg" alt=""/></a>
            </div>
            <div className="tpoffcanvas__info text-center">
               <h4 className="offcanva-title">we are here</h4>
               <a href="https://www.google.com/maps/@23.506657,90.3443647,7z" target="_blank">
               <p>1208, Shivalik Shilp, 
                           Satellite,  <br/>
                           Ahmedabad, INDIA</p>

               </a>
            </div>
            <div className="tpoffcanvas__social">
               <div className="social-icon text-center">
                  <a href="#"><i className="fab fa-twitter"></i></a>
                  <a href="#"><i className="fab fa-instagram"></i></a>
                  <a href="#"><i className="fab fa-facebook-square"></i></a>
                  <a href="#"><i className="fab fa-dribbble"></i></a>
               </div>
            </div>
         </div>
      </div>
      <div className={menuStatus == "opened" ? "body-overlay apply" : "body-overlay"} onClick={handleCloseMenu} ></div>
    {/* <!-- tp-offcanvus-area-end --> */}

        <header>
      {/* <!-- tp-header-area-start --> */}
      <div id="header-sticky" className="header-bottom__area header-sticky-bg-2 header-bottom__transparent header-bottom__bdr z-index-5">
         <div className="container">
            <div className="row g-0 align-items-center">
              <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-4 col-6">
                  <div className="header-bottom__logo">
                     {/* <a className="white-logo" href="#">
                           <img src="assets/img/logo/logo-only.png" alt=""/>
                           <b> THEDigitaLogic </b>
                     </a> */}
                     <a className="logo white-logo" href="/" >
                        <img src="assets/img/logo/logo.png" alt=""/>
                         <b> THEDigitaLogic </b>
                     </a>
                  </div>
               </div>
               <div className="col-xxl-7 col-xl-7 col-lg-7 d-none d-lg-block">
                  <div className="header-bottom__main-menu header-bottom__main-menu-4 header-bottom__main-menu-inner">
                     <nav id="mobile-menu">
                        <ul>
                           <li>
                              <a href="/">Home</a>
                              
                           </li>
                           <li>
                              <a href="/About">About</a>
                           </li>
                           <li>
                              <a href="/Projects">Projects</a>
                              
                           </li>
                           <li><a href="/Services">Services</a></li>
                           <li> <a href="/Career">Career</a></li>
                           
                           <li><a href="/Contact">Contact</a></li>
                        </ul>
                     </nav>
                  </div>
               </div>
               <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-8 col-6">
                  <div className="header-bottom__right d-flex align-items-center justify-content-end">
                  <div className="header-bottom__action header-bottom__action-4 d-none d-xl-block">
                        {/* <a className="d-none d-lg-inline-block header-bottom__action-2 border-none" href="register.html">
                           <svg width="14" height="16" viewBox="0 0 14 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                 <path
                                    d="M7 8C8.92882 8 10.4924 6.433 10.4924 4.5C10.4924 2.567 8.92882 1 7 1C5.07118 1 3.50757 2.567 3.50757 4.5C3.50757 6.433 5.07118 8 7 8Z"
                                    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                 <path d="M13 15C13 12.291 10.3108 10.1 7 10.1C3.68917 10.1 1 12.291 1 15" stroke="currentColor"
                                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                           </svg>
                           <span>Log In</span>
                        </a> */}
                     </div>
                     <div className="header-bottom__btn d-flex align-items-center">
                        {/* <a className="tp-btn-white tp-btn-hover alt-color-black d-none d-md-inline-block" href="service-details.html">
                           <span className="white-text">Get Started</span>
                           <b></b>
                        </a> javascript:void(0) */}
                        <a className="header-bottom__bar tp-menu-bar d-lg-none" href="#"  onClick={handleOpenMenu} ><i className="fal fa-bars"></i></a>
                     </div>
                   
                  </div>
               </div>
            </div>
         </div>
      </div>
      {/* <!-- tp-header-area-end --> */}
   </header>
   
   </> 
    );
}
