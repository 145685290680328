import React, { useEffect, useState , Component ,useReducer} from "react";
import Footer1 from "../../Common/Footer1";
import Header1 from "../../Common/Header1";
import { useLocation, useNavigate } from 'react-router-dom';
import jsonData from '../../../JSONData/ServiceData.json';
import useQuery from "../../../hooks/useQuery";
import ServiceOverview from "./ServiceOverview";

export default function ServiceDetails()  {
   
   const [serviceId, setServiceId] = useState();

   const [serviceList, setServiceList] = useState([]);

   const [servicetitle, setservicetitle] = useState('');

   const [service, setservice] = useState([]);

   const [servicePointList, setServicePointList] = useState([]);

   const query = useQuery();
   const serviceID = query.get("serviceId") || "";
   
   const divStyle = {

      // color: 'lightgray',
    
      color: 'rgba(95, 97, 104, 0.7)',
    
    };
    

          
   useEffect(() => {
      let tempServiceId = serviceID;
         //    debugger;
         setServiceId(tempServiceId);
         setServiceList(jsonData);
      const data = jsonData.find((d) => d.id == tempServiceId);
      if (data) {
        // debugger;
         setservice(data);
         setservicetitle(data.Service_name);
         setServicePointList(data.Service_providing_points);
      }
      //   debugger;

   }, []);

   const handleClick = (event,param) => {
       event.preventDefault();
      setServiceId(param);
      const data = jsonData.find((d) => d.id == param);
      if (data) {
         debugger;
         setservice(data);
         setservicetitle(data.Service_name);
         setServicePointList(data.Service_providing_points);
         <ServiceOverview service={data}
       servicePointList={data.Service_providing_points} />

      }
     
       
    };
  
   
   
   return (
      <>
         <Header1 />

         <div id="smooth-wrapper">
            <div id="smooth-content">

               <main>

                  {/* <!-- breadcrumb-area-start --> */}
                  {/*breadcrumb__area breadcrumb-height breadcrumb-overlay p-relative fix*/}
                  
                  <div className="breadcrumb__area breadcrumb-height p-relative blue-bg-2">
                     <div className="breadcrumb__shape-1">
                        <img src="assets/img/breadcrumb/breadcrumb-shape-1.png" alt="" />
                     </div>
                     <div className="breadcrumb__shape-2">
                        <img src="assets/img/breadcrumb/breadcrumb-shape-2.png" alt="" />
                     </div>
                     <div className="container">
                        <div className="row">
                           <div className="col-xl-8 col-lg-7">
                              <div className="breadcrumb__content">

                                 {/* <h3 className="breadcrumb__title tp-char-animation">{servicetitle}</h3>*/}
                                 <div className="breadcrumb__list tp-title-anim">
                                    <span className="child-one"><a href="/#" style={divStyle}>Home</a></span>
                                    <span className="dvdr"><i className="fal fa-angle-right"></i></span>
                                    <span >Services</span>
                                 </div>
                              </div>
                           </div>
                           <div className="col-xl-4 col-lg-5 col-lg-4 text-center text-md-end">
                              <div className="breadcrumb__img p-relative text-start z-index">
                                 <img className="z-index-3" src="assets/img/breadcrumb/breadcrumb-3.png" alt="" />
                                 <div className="breadcrumb__sub-img wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".4s">
                                    <img src="assets/img/breadcrumb/breadcrumb-sub-1.png" alt="" />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  {/* <!-- breadcrumb-area-end --> */}

                  {/* <!-- service-details-area-start --> */}
                  <div className="sv-details-area sv-details-space pt-100 pb-100">
                     <div className="container">
                        <div className="row">
                           <div className="col-xl-4 col-lg-4">
                              <div className="sv-details-widget">
                                 <div className="sv-details-category mb-30">
                                    <div className="sv-details-category-title">
                                       <h4 className="sv-details-title-sm">Service Category</h4>
                                    </div>
                                    <div className="sv-details-category-list">
                                       <ul>


                                          {serviceList.map((s) => (
                                             (() => {
                                                if (servicetitle == s.Service_name) {
                                                   return (
                                                      <li className="active">
                                                         <a onClick={event => handleClick(event, s.id)} href="" ><span>{s.Service_name}</span><i className="fal fa-angle-right"></i></a>
                                                      </li>);
                                                } else {
                                                   return (
                                                      <>
                                                         <li>
                                                            <a onClick={event => handleClick(event, s.id)} href=""><span>{s.Service_name}</span><i className="fal fa-angle-right"></i></a>
                                                         </li>
                                                      </>
                                                   );
                                                }

                                             })()
                                          ))}




                                          {/* {serviceList.map((s) => (
                                 
                              //     <li>
                              //       <a href="#"><span>{s.Service_name}</span><i className="fal fa-angle-right"></i></a>
                              //    </li>
                                
                              
                               //))}
                            
                                 <li>
                                    <a href="#"><span>Branding</span><i className="fal fa-angle-right"></i></a>
                                 </li>
                                 <li className="active">
                                    <a href="#"><span>Web Design</span><i className="fal fa-angle-right"></i></a>
                                 </li>
                                 <li>
                                    <a href="#"><span>Mobile Application</span><i className="fal fa-angle-right"></i></a>
                                 </li>
                                 <li>
                                    <a href="#"><span>Development</span><i className="fal fa-angle-right"></i></a>
                                 </li>
                                 <li>
                                    <a href="#"><span>Illusutration</span><i className="fal fa-angle-right"></i></a>
                                 </li>*/}
                                       </ul>
                                    </div>
                                 </div>
                                 
                                
                              </div>
                           </div>
                           <ServiceOverview service={service}
                           servicePointList={servicePointList} />

     
                           {/* <div className="col-xl-8 col-lg-8">
                              <div className="sv-details-wrapper">
                                 <div className="sv-details-thumb mb-45">
                                    <img className="w-100" src={service.Service_image} alt="" />
                                 </div>
                                 <div className="sv-details-title-box mb-55">
                                    <h4 className="sv-details-title">{service.Service_name} Service Overview</h4>
                                    <p>{service.Service_detail}</p>
                                 </div>
                                 <div className="sv-details-text mb-35">
                                    <h4 className="sv-details-text-title pb-10">Going Beyond the Usual</h4>
                                    <ul>
                                       {servicePointList.map((sp) => (
                                          <li>
                                             <i className="fal fa-check"></i>
                                             <p>
                                                {sp.Title} <br />
                                                <span>{sp.Description}</span>
                                             </p>
                                          </li>
                                       ))}

                                       {/* <li>
                                 <i className="fal fa-check"></i>
                                 <p>
                                    Product Quality: <br/>
                                    <span>Automatically syncs across all your devices. You can also access....</span>
                                 </p>
                              </li>
                              <li>
                                 <i className="fal fa-check"></i>
                                 <p>
                                    On Demand Design: <br/>
                                    <span>Images, videos, PDFs and audio files are supported.</span>
                                 </p>
                              </li>
                              <li>
                                 <i className="fal fa-check"></i>                                 
                                 <p>
                                    Choice of Service:
                                    <span>Whatever your business needs, you can choose a service.</span>
                                 </p>
                              </li> 
                                    </ul>
                                 </div>
                                 <div className="sv-details-text-2">
                                    <h4 className="sv-details-text-title">The Challange</h4>
                                    <p>{service.Service_challenge}</p>
                                 </div>

                              </div>
                           </div> */}
                        </div>
                     </div>
                  </div>
                  {/* <!-- service-details-area-end -->*/}
 

               </main>
               <Footer1 />
            </div>
         </div>

      </>
   );
}

