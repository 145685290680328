import React,{useEffect , useState} from "react";  

import Footer1 from "../../Common/Footer1";
import Header1 from "../../Common/Header1";
import JsonData from "../../../JSONData/ServiceData.json";

export default function Services() {

   const [serviceList, setServiceList] = useState([]);
   
  
  useEffect(() => {
      setServiceList(JsonData);
   }, []);


    return (
        <>
         <Header1/> 
        
        <div id="smooth-wrapper">
        <div id="smooth-content">
  
           <main>
  
             {/* <!-- breadcrumb-area-start --> */}
            <div class="breadcrumb__area breadcrumb-height-2 breadcrumb-overlay p-relative fix"
           >
             {/* data-background="assets/img/project/project-brdcrmb-bg.jpg"> */}
               <div class="breadcrumb__shape-2 z-index-4">
                  <img src="assets/img/breadcrumb/breadcrumb-shape-2-2.png" alt=""/>
               </div>
               <div class="breadcrumb__shape-3 z-index-4">
                  <img src="assets/img/breadcrumb/breadcrumb-sub-2.png" alt=""/>
               </div>
               <div class="breadcrumb__shape-4 z-index-4">
                  <img src="assets/img/breadcrumb/breadcrumb-sub-3.png" alt=""/>
               </div>
               <div class="container">
                  <div class="row justify-content-center">
                     <div class="col-xl-10">
                        <div class="breadcrumb__content z-index-3 text-center">
                           <h3 class="breadcrumb__title tp-char-animation text-black">Our Services</h3>
                           <div class="breadcrumb__text wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".6s">
                              <p>We have an experienced team of production and inspection personnel <br/> 
                              to ensure
                                 quality.</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
  
              {/* <!-- tp-service-area-strat --> */}
              <div class="tp-service-area pt-100 pb-120 z-index">
                 <div class="container">
                    <div class="row">
                       <div class="col-xl-12">
                          <div class="tp-service-section-wrapper mb-60 d-flex justify-content-between align-items-end">
                             <h3 class="tp-section-title tp-title-anim"> Innovate, Integrate, Elevate  <br/>
                             Your Vision, Our Code.</h3> 
                                                             {/* <a class="tp-btn-inner tp-btn-hover alt-color-black wow tpfadeRight"
                                 data-wow-duration=".9s" data-wow-delay=".3s" href="/#">
                                   <span>See All Features</span>
                                   <b></b>
                                </a> */}
                          </div>
                       </div>
                    </div>
                    <div class="row">
                       <div class="col-xl-12 wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".3s">
                          <div class="tp-service-3-item mb-30 p-relative z-index" 
                          style={{backgroundImage:'url("assets/img/service/service-3-bg.png")'}}
                         >
                             <div class="tp-service-3-icon">
                                <img src="assets/img/service/sv-icon-3-1.png" alt=""/>
                             </div>
                             <div class="tp-service-3-content">
                                <span>CRM Management</span>
                                <h4 class="tp-service-3-title-sm"><a href="/#">Generate, Manage, and Convert leads <br/> 
                                into Customers. Automatically</a></h4>
                             </div>
                             <div class="tp-service-3-btn">
                                <a class="tp-btn-white-solid" href="/#">Learn More</a>
                             </div>
                             <div class="tp-service-3-shape">
                                <img src="assets/img/service/service-shape-3-1.png" alt=""/>
                             </div>
                          </div>
                       </div>
                       
                    </div>
                    <div className="tp-service__area p-relative fix">
               {/* <div className="tp-service__grey-shape grey-bg"></div> */}
               <div className="container">
                  <div className="row justify-content-center">
                     <div className="col-lg-8">
                        <div className="tp-service__section-box mb-50 text-center tp-title-anim">
                           <h2 className="tp-section-title">Explore Our Services</h2>
                           <p>More than 50 companies trust and choose TheDigitaLogic</p>
                        </div>
                     </div>
                  </div>
                  <div className="row">
                  {/* serviceList */}
                  {serviceList.map(s => (
                  // <li>{s}</li>
                  <div className="col-xl-4 col-lg-4 col-md-6 wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".9s">
                        <div className="tp-service__item mb-30">
                           <div className="tp-service__icon">
                              <img src={s.Service_icon} alt=""/>
                           </div>
                           <div className="tp-service__content">
                              <h3 className="tp-service__title-sm tp-yellow-color"><a href={"/ServiceDetails?serviceId="+ s.id} 
                              >{s.Service_name} 
</a></h3>
                                     <p>{s.Service_description}</p>
                           </div>
                           <div className="tp-service__link">
                               <a href={"/ServiceDetails?serviceId="+ s.id}> {/*onClick={this.handleQueryString}
                              > */}
                                 <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.00098 7H13.001" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M7.00098 1L13.001 7L7.00098 13" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                 </svg>
                              </a>
                             
                           </div>
                        </div>
                     </div>
                  
                  
                  
                  
                  
                  
                  
                  
                  
                  
                  
                  
                  ))}
                  {/* <div className="col-xl-4 col-lg-4 col-md-6 wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".9s">
                        <div className="tp-service__item mb-30">
                           <div className="tp-service__icon">
                              <img src="assets/img/service/sv-icon-5.png" alt=""/>
                           </div>
                           <div className="tp-service__content">
                              <h3 className="tp-service__title-sm tp-yellow-color"><a href="" id={1} onClick={shoot}>Website designing 
</a></h3>
                                     <p>Website designing is a crucial aspect of creating a successful online presence <br />
                                     for individuals, businesses, or organizations.</p>
                           </div>
                           <div className="tp-service__link">
                              <a href="/#">
                                 <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.00098 7H13.001" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M7.00098 1L13.001 7L7.00098 13" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                 </svg>
                              </a>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-4 col-lg-4 col-md-6 wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".8s">
                        <div className="tp-service__item mb-30">
                           <div className="tp-service__icon">
                              <img src="assets/img/service/sv-icon-4.png" alt=""/>
                           </div>
                           <div className="tp-service__content">
                              <h3 className="tp-service__title-sm tp-green-color"><a href="/#">UI/UX Design
</a></h3>
                              <p>User Interface/User Experience design, is a critical aspect of creating digital products that are not  <br/>
                              only visually appealing but also intuitive and user-friendly. </p>
                           </div>
                           <div className="tp-service__link">
                              <a href="/#">
                                 <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.00098 7H13.001" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M7.00098 1L13.001 7L7.00098 13" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                 </svg>
                              </a>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-4 col-lg-4 col-md-6 wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".8s">
                        <div className="tp-service__item mb-30">
                           <div className="tp-service__icon">
                              <img src="assets/img/service/sv-icon-4.png" alt=""/>
                           </div>
                           <div className="tp-service__content">
                              <h3 className="tp-service__title-sm tp-green-color"><a href="/#">Web App Development

                                 </a></h3>
                                     <p> Involves designing and building interactive applications
                                        accessible through web browsers, incorporating front-end
                                        interfaces,
                                        <br />
                                        back-end logic, and database integration for seamless user experiences. </p>
                           </div>
                           <div className="tp-service__link">
                              <a href="/#">
                                 <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.00098 7H13.001" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M7.00098 1L13.001 7L7.00098 13" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                 </svg>
                              </a>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-4 col-lg-4 col-md-6 wow tpfadeUp" data-wow-duration=".9s" 
                     data-wow-delay=".7s">
                        <div className="tp-service__item mb-30">
                           <div className="tp-service__icon">
                              <img src="assets/img/service/sv-icon-3.png" alt=""/>
                           </div>
                           <div className="tp-service__content">
                              <h3 className="tp-service__title-sm tp-purple-color"><a href="/#">Mobile App Development</a></h3>
                              <p>Our proficiency extends to developing responsive and intuitive  <br/>
                              web and mobile applications</p>
                           </div>
                           <div className="tp-service__link">
                              <a href="/#">
                                 <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.00098 7H13.001" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M7.00098 1L13.001 7L7.00098 13" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                 </svg>
                              </a>
                           </div>
                        </div>
                     </div>
                     
                     <div className="col-xl-4 col-lg-4 col-md-6 wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".4s">
                        <div className="tp-service__item mb-30">
                           <div className="tp-service__icon">
                              <img src="assets/img/service/sv-icon-1.png" alt=""/>
                           </div>
                           <div className="tp-service__content">
                              <h3 className="tp-service__title-sm tp-orange-color"><a href="/#">
                                 Custom Software Development</a></h3>
                              <p>We excel in crafting bespoke software solutions tailored <br/>
                              to meet the unique needs and challenges of 
                              our clients. </p>
                           </div>
                           <div className="tp-service__link">
                              <a href="/#">
                                 <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.00098 7H13.001" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M7.00098 1L13.001 7L7.00098 13" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                 </svg>
                              </a>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-4 col-lg-4 col-md-6 wow tpfadeUp" data-wow-duration=".9s"
                      data-wow-delay=".6s">
                        <div className="tp-service__item mb-30">
                           <div className="tp-service__icon">
                              <img src="assets/img/service/sv-icon-2.png" alt=""/>
                           </div>
                           <div className="tp-service__content">
                              <h3 className="tp-service__title-sm tp-theme-color">
                                 <a href="/#">Window App Development</a></h3>
                              <p>From concept to deployment, we bring ideas to life with  <br/>
                              precision and efficiency</p>
                           </div>
                           <div className="tp-service__link">
                              <a href="/#">
                                 <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.00098 7H13.001" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M7.00098 1L13.001 7L7.00098 13" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                 </svg>
                              </a>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-4 col-lg-4 col-md-6 wow tpfadeUp" data-wow-duration=".9s"
                      data-wow-delay=".6s">
                        <div className="tp-service__item mb-30">
                           <div className="tp-service__icon">
                              <img src="assets/img/service/sv-icon-2.png" alt=""/>
                           </div>
                           <div className="tp-service__content">
                              <h3 className="tp-service__title-sm tp-theme-color">
                                        <a href="/#">Digital Marketing
                                        </a></h3>
                              <p>use of online channels and platforms to promote products, services, 
                                 or brands, employing strategies such as social media marketing,  (SEO), 
                                 content marketing,  <br/>
                                 email marketing to reach and engage target audiences.</p>
                           </div>
                           <div className="tp-service__link">
                              <a href="/#">
                                 <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.00098 7H13.001" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M7.00098 1L13.001 7L7.00098 13" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                 </svg>
                              </a>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-4 col-lg-4 col-md-6 wow tpfadeUp" data-wow-duration=".9s"
                      data-wow-delay=".6s">
                        <div className="tp-service__item mb-30">
                           <div className="tp-service__icon">
                              <img src="assets/img/service/sv-icon-2.png" alt=""/>
                           </div>
                           <div className="tp-service__content">
                              <h3 className="tp-service__title-sm tp-theme-color">
                                        <a href="/#">E-commerce Solutions
                                        </a></h3>
                              <p>the comprehensive set of technologies, tools, and services that enable businesses to conduct online transactions, 
                                 manage digital storefronts,  <br/>
                                 and facilitate the buying and selling of goods and services over the internet.</p>
                           </div>
                           <div className="tp-service__link">
                              <a href="/#">
                                 <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.00098 7H13.001" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M7.00098 1L13.001 7L7.00098 13" stroke="currentColor" strokeWidth="1.5"
                                       strokeLinecap="round" strokeLinejoin="round" />
                                 </svg>
                              </a>
                           </div>
                        </div>
                     </div>
                      */}

                     {/* <div className="col-xl-4 col-lg-4 col-md-6 wow tpfadeUp" data-wow-duration=".9s" data-wow-delay="1s">
                        <div className="tp-service__dashboard"
                        style={{backgroundImage: `url("assets/img/service/sv-bg.jpg")`}}
                       >
                       
                                  <div className="tp-service__top-content">
                                     <h3 className="tp-service__title-white">Software Testing and
                                        <br /> Quality Assurance</h3>
                                     <p>systematic process and set of activities implemented within a project or organization to ensure that the developed products or services meet 

                                        <br /> specified requirements and comply with established standards.</p>
                              <a className="tp-btn-orange tp-btn-hover alt-color-white" 
                              href="/#">
                                 <span>Work with Us</span>
                                 <b></b>
                              </a>
                           </div>
                           <div className="tp-service__dashdboard-sm-img">
                              <img className="wow tpfadeRight" data-wow-duration=".9s" data-wow-delay=".7s" 
                              src="assets/img/service/sv-dashbord.png" alt=""/>
                           </div>
                        </div>
                     </div> */}
                  </div>
               </div>
            </div>
           
                 </div>
              </div>
              {/* <!-- tp-service-area-end --> */}
  
              {/* <!-- tp-card-area-start --> */}
              {/* <!-- tp-card-area-end --> */}
  
              {/* <!-- tp-sales-area-start --> */}
              {/* <!-- tp-sales-area-end --> */}
  
              {/* <!-- tp-testimonial-area-start --> */}
              {/* <!-- tp-testimonial-area-end --> */}
  
              {/* <!-- tp-faq-area-start --> */}
              {/* <!-- tp-faq-area-end --> */}
  
              {/* <!-- tp-cta-area-start --> */}
              <div class="tp-cta-area p-relative">
                 <div class="tp-cta-grey-bg grey-bg-2"></div>
                 <div class="container">
                    <div class="row">
                       <div class="col-12">
                          <div class="tp-cta-bg" style={{backgroundImage:'url("assets/img/cta/cta-bg.jpg")'}}
                           >
                             <div class="tp-cta-content tp-inner-font text-center">
                                <h3 class="tp-section-title text-white">Try our service now!</h3>
                                <p>Eyerything you need to accept cord payments and grow your business <br/>
                                   anywhere on the planet.</p>
                                   <a class="tp-btn-inner white-bg text-black" href="/#">
                                    Get Started Now</a>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
              {/* <!-- tp-cta-area-end --> */}
  
           </main>
           <Footer1/>
           </div>
           </div>
           </>
    );
}
